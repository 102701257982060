import React from "react";

export default function PrivacyPolicy() {
  return (
    <div className="bg-gray-50 py-12 sm:py-16 lg:py-24">
      <div className="max-w-7xl mx-auto px-6 lg:px-8">
      <p className=" text-center text-lg font-semibold leading-7 text-[#3B725C]">Dreirosen Apotheke</p>
      <h1 className="mt-3 text-4xl font-extrabold text-center tracking-tight text-gray-900 sm:text-5xl">
          Privacy Policy
        </h1>
        <p className="mt-4 max-w-3xl mx-auto text-base text-gray-600 text-center text-lg leading-8">
          Your privacy is important to us. This Privacy Policy explains how we collect, use, and share your personal information.
        </p>

        <div className="mt-10 bg-white rounded-lg shadow-lg p-6 md:p-8 lg:p-10 space-y-12">
          {/* Section 1 */}
          <div className="border-l-4 border-[#3B725C] pl-4">
            <h2 className="text-2xl font-bold text-[#3B725C] mb-4">1. Information We Collect</h2>
            <p className="text-gray-600">
              We collect information you provide when you create an account, place an order, subscribe to our newsletter, or contact us for customer support. The types of information we may collect include your name, email address, mailing address, phone number, payment information, and any other information you choose to provide.
            </p>
          </div>

          {/* Section 2 */}
          <div className="border-l-4 border-[#3B725C] pl-4">
            <h2 className="text-2xl font-bold text-[#3B725C] mb-4">2. How We Use Your Information</h2>
            <p className="text-gray-600">
              We use the information we collect to fulfill orders, provide customer support, improve our services, and communicate with you about promotions, updates, or other relevant information. We may also use this information to ensure the security of our platform.
            </p>
          </div>

          {/* Section 3 */}
          <div className="border-l-4 border-[#3B725C] pl-4">
            <h2 className="text-2xl font-bold text-[#3B725C] mb-4">3. How We Share Your Information</h2>
            <p className="text-gray-600">
              We may share your information with third-party service providers, such as payment processors or shipping partners, to complete transactions. We do not sell your personal information to third parties for marketing purposes.
            </p>
          </div>

          {/* Section 4 */}
          <div className="border-l-4 border-[#3B725C] pl-4">
            <h2 className="text-2xl font-bold text-[#3B725C] mb-4">4. Your Data Protection Rights</h2>
            <p className="text-gray-600">
              You have the right to request access to your personal data, request correction of any errors, request deletion, and object to certain processing activities. If you wish to exercise any of these rights, please contact our customer service.
            </p>
          </div>

          {/* Section 5 */}
          <div className="border-l-4 border-[#3B725C] pl-4">
            <h2 className="text-2xl font-bold text-[#3B725C] mb-4">5. Security</h2>
            <p className="text-gray-600">
              We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction. However, no method of transmission over the Internet is entirely secure, so we cannot guarantee its absolute security.
            </p>
          </div>

          {/* Section 6 */}
          <div className="border-l-4 border-[#3B725C] pl-4">
            <h2 className="text-2xl font-bold text-[#3B725C] mb-4">6. Changes to This Policy</h2>
            <p className="text-gray-600">
              We may update this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of the policy or providing other appropriate communication.
            </p>
          </div>

          {/* Section 7 */}
          <div className="border-l-4 border-[#3B725C] pl-4">
            <h2 className="text-2xl font-bold text-[#3B725C] mb-4">7. Contact Us</h2>
            <p className="text-gray-600">
              If you have any questions or concerns about this Privacy Policy, feel free to contact us at <a href="mailto:support@pharmacyshop.com" className="text-indigo-600 hover:underline">support@pharmacyshop.com</a>.
            </p>
          </div>
        </div>

        <div className="mt-16 text-center">
          <p className="text-sm text-gray-500">&copy; {new Date().getFullYear()} Pharmacy Shop. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
}
