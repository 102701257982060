import React, { useEffect, useRef, useState } from 'react';
import { Popover } from '@headlessui/react';
import { fetchCategories } from '../services/apiService'; 
import { Link, useNavigate } from 'react-router-dom'; 
import { logoutUser } from '../services/apiService';

export default function Sidebar({ solutions, callsToAction, company, resources }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [currentPopover, setCurrentPopover] = useState(null); 
  const [categories, setCategories] = useState([]); 
  const sidebarRef = useRef(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate(); 

  useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem('token');
      setIsLoggedIn(!!token);
    };
    
    checkAuth();
  }, []);

  // Add this new function
  const handleLogout = () => {
    logoutUser();
    navigate('/loginpage');
    setIsSidebarOpen(false);
  };

  useEffect(() => {
    if (isSidebarOpen) {
      document.body.style.overflow = 'hidden'; 
    } else {
      document.body.style.overflow = ''; 
    }

    return () => {
      document.body.style.overflow = ''; 
    };
  }, [isSidebarOpen]);

  const fetchSolutionCategories = async (solution) => {
    try {
      const response = await fetchCategories();
      const fetchedData = response.data;

      const categoryMapping = {
        "Medication & Treatment": ["PainFever", "FluColds"],
        "Nutrition & Health": ["Nutrition"],
        "Baby & Parents": ["MotherChild"],
        "Beauty & Personal Care": ["FacialSkinCareMakeUp", "SkinHairNails"],
        "Household & Cleaning": ["HouseGarden"],
        "Medical Supplies & Nursing Care": ["FirstAidDressingMat"]
      };

      const relevantCategories = categoryMapping[solution.name] || [];
      const matchingCategories = fetchedData.filter((category) =>
        relevantCategories.includes(category.category.en)
      );

      setCategories(matchingCategories); 
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleBackToMain = () => {
    setCurrentPopover(null); 
  };

  const handleSubcategoryClick = (subcategoryId) => {
    const cleanSubcategoryId = subcategoryId.trim();
    navigate(`/categories/${cleanSubcategoryId}/products`);
    setIsSidebarOpen(false);
  };

  const handlePanelClose = () => {
    setIsSidebarOpen(false);
  };

  return (
    <Popover.Panel
      className="absolute inset-x-0 top-0 z-30 origin-top-right transform p-2 transition-transform duration-200 ease-out data-[state=closed]:scale-95 data-[state=closed]:opacity-0 custom:hidden max-h-[100vh] overflow-y-auto overflow-x-hidden min-h-[100vh]"
    >
      {({ close }) => (
        <div
          className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 min-h-[100vh] pt-safe-bottom pb-[env(safe-area-inset-bottom)]"
          ref={sidebarRef}
        >
          {currentPopover ? (
            <div>
              <div className="flex items-center p-5">
                <button onClick={handleBackToMain} className="text-gray-500 hover:text-gray-700">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                    <path
                      fillRule="evenodd"
                      d="M15.78 3.28a.75.75 0 010 1.06L9.06 11l6.72 6.72a.75.75 0 11-1.06 1.06l-7-7a.75.75 0 010-1.06l7-7a.75.75 0 011.06 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                <h2 className="ml-4 text-lg font-medium text-gray-900">{currentPopover.name}</h2>
              </div>

              <div className="p-10">
                {categories.length > 0 ? (
                  categories.map((category) => (
                    <div key={category._id} className="mb-4">
                      <ul className="space-y-5">
                        {category.subcategories.map((subcategory, subIdx) => (
                          <li key={subIdx}>
                            <button
                              onClick={() => {
                                const cleanSubcategoryId = subcategory.en.trim();
                                navigate(`/categories/${cleanSubcategoryId}/products`, { replace: true });
                                setIsSidebarOpen(false);
                                setCurrentPopover(null);
                                close(); // This ensures the Popover panel closes
                              }}
                              className="text-lg text-gray-900 hover:text-indigo-900"
                            >
                              {subcategory.en}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))
                ) : (
                  <p>No categories found.</p>
                )}
              </div>
            </div>
          ) : (
            <div>
              <div className="px-5 pb-6 pt-5 sm:pb-8">
                <div className="flex items-center justify-between">
                  <div className="-mr-2">
                    <button
                      className="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#53a751]"
                      onClick={() => {
                        close();
                        handlePanelClose();
                      }}
                    >
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Close menu</span>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                        <path
                          fillRule="evenodd"
                          d="M6.225 6.225a.75.75 0 011.06 0L12 10.94l4.715-4.715a.75.75 0 011.06 1.06L13.06 12l4.715 4.715a.75.75 0 11-1.06 1.06L12 13.06l-4.715 4.715a.75.75 0 11-1.06-1.06L10.94 12 6.225 7.285a.75.75 0 010-1.06z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                </div>

                <div className="mt-6 sm:mt-8">
                  <nav>
                    <div className="grid gap-7 sm:grid-cols-2 sm:gap-x-4 sm:gap-y-8">
                      {solutions.map((item) => (
                        <div 
                          key={item.name} 
                          className="flex items-center justify-between cursor-pointer hover:bg-gray-50 rounded-lg"
                          onClick={() => {
                            setCurrentPopover(item);
                            fetchSolutionCategories(item);
                          }}
                        >
                          <a
                            href={item.href}
                            className="-m-3 flex items-center rounded-lg p-3"
                            onClick={(e) => e.preventDefault()}
                          >
                            <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-[#53a751] text-white sm:h-12 sm:w-12">
                              {item.icon}
                            </div>
                            <div className="ml-4 text-base font-medium text-gray-900">{item.name}</div>
                          </a>
                          <button className="text-gray-500 hover:text-gray-700 p-3">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                              <path
                                fillRule="evenodd"
                                d="M8.22 3.28a.75.75 0 011.06 0l7 7a.75.75 0 010 1.06l-7 7a.75.75 0 11-1.06-1.06L14.94 11 8.22 4.28a.75.75 0 010-1.06z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>
                        </div>
                      ))}
                    </div>
                    <div className="mt-8 text-base">
                      <a href="/products" className="font-medium text-[#53a751]">
                        View all products
                        <span aria-hidden="true"> &rarr;</span>
                      </a>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          )}

          {!currentPopover && (
            <div className="px-5 py-6">
              {/* Add the profile section at the top */}
              {isLoggedIn && (
                <div className="mb-8">
                  <h3 className="text-base font-medium text-gray-900 mb-4">My Account</h3>
                  <div className="grid grid-cols-1 gap-4">
                    <a 
                      href="/profilepage" 
                      className="flex items-center p-3 bg-gray-100 rounded-lg text-gray-600 shadow-sm hover:text-gray-900 hover:bg-gray-200"
                    >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 mr-3"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path
            d="M6.02958 19.4012C5.97501 19.9508 6.3763 20.4405 6.92589 20.4951C7.47547 20.5497 7.96523 20.1484 8.01979 19.5988L6.02958 19.4012ZM15.9802 19.5988C16.0348 20.1484 16.5245 20.5497 17.0741 20.4951C17.6237 20.4405 18.025 19.9508 17.9704 19.4012L15.9802 19.5988ZM20 12C20 16.4183 16.4183 20 12 20V22C17.5228 22 22 17.5228 22 12H20ZM12 20C7.58172 20 4 16.4183 4 12H2C2 17.5228 6.47715 22 12 22V20ZM4 12C4 7.58172 7.58172 4 12 4V2C6.47715 2 2 6.47715 2 12H4ZM12 4C16.4183 4 20 7.58172 20 12H22C22 6.47715 17.5228 2 12 2V4ZM13 10C13 10.5523 12.5523 11 12 11V13C13.6569 13 15 11.6569 15 10H13ZM12 11C11.4477 11 11 10.5523 11 10H9C9 11.6569 10.3431 13 12 13V11ZM11 10C11 9.44772 11.4477 9 12 9V7C10.3431 7 9 8.34315 9 10H11ZM12 9C12.5523 9 13 9.44772 13 10H15C15 8.34315 13.6569 7 12 7V9ZM8.01979 19.5988C8.22038 17.5785 9.92646 16 12 16V14C8.88819 14 6.33072 16.3681 6.02958 19.4012L8.01979 19.5988ZM12 16C14.0735 16 15.7796 17.5785 15.9802 19.5988L17.9704 19.4012C17.6693 16.3681 15.1118 14 12 14V16Z"
          />
        </svg>
                      <span>Profile</span>
                    </a>
                    
                    <Link 
                      to="/userfavoritespage" 
                      className="flex items-center p-3 bg-gray-100 rounded-lg text-gray-600 shadow-sm hover:text-gray-900 hover:bg-gray-200"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 mr-3"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M15.7 4C18.87 4 21 6.98 21 9.76C21 15.39 12.16 20 12 20C11.84 20 3 15.39 3 9.76C3 6.98 5.13 4 8.3 4C10.12 4 11.31 4.91 12 5.71C12.69 4.91 13.88 4 15.7 4Z"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span>Favorites</span>
                    </Link>
                    
                    <a 
                      href="/cartpage" 
                      className="flex items-center p-3 bg-gray-100 rounded-lg text-gray-600 shadow-sm hover:text-gray-900 hover:bg-gray-200"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 mr-3"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M6.29977 5H21L19 12H7.37671M20 16H8L6 3H3M9 20C9 20.5523 8.55228 21 8 21C7.44772 21 7 20.5523 7 20C7 19.4477 7.44772 19 8 19C8.55228 19 9 19.4477 9 20ZM20 20C20 20.5523 19.5523 21 19 21C18.4477 21 18 20.5523 18 20C18 19.4477 18.4477 19 19 19C19.5523 19 20 19.4477 20 20Z" />
                      </svg>
                      <span>Cart</span>
                    </a>
                  </div>
                </div>
              )}

              {/* Rest of your existing sidebar content */}
              <div className="grid grid-cols-2 gap-4">
                {company.map((item) => (
                  <a key={item.name} href={item.href} className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
                    {item.name}
                  </a>
                ))}
                {resources.map((item) => (
                  <a key={item.name} href={item.href} className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
                    {item.name}
                  </a>
                ))}
              </div>

              {/* Authentication buttons at the bottom */}
              <div className="mt-6">
                {!isLoggedIn ? (
                  <>
                    <a
                      href="/registerpage"
                      className="flex w-full items-center justify-center rounded-md border border-transparent bg-[#53a751] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-[#458b43]"
                    >
                      Sign up
                    </a>
                    <p className="mt-6 text-center text-base font-medium text-gray-500">
                      Existing customer?{' '}
                      <a href="/loginpage" className="text-[#53a751] hover:text-[#458b43]">
                        Sign in
                      </a>
                    </p>
                  </>
                ) : (
                  <button
                    onClick={handleLogout}
                    className="flex w-full items-center justify-center rounded-md bg-[#51A550] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-[#458b43] space-x-2"
                  >
                    <span>Logout</span>
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      width="20" 
                      height="20" 
                      viewBox="0 0 24 24" 
                      fill="none"
                      className="ml-2"
                    >
                      <path 
                        d="M15 16.5V19C15 20.1046 14.1046 21 13 21H6C4.89543 21 4 20.1046 4 19V5C4 3.89543 4.89543 3 6 3H13C14.1046 3 15 3.89543 15 5V8.0625M11 12H21M21 12L18.5 9.5M21 12L18.5 14.5" 
                        stroke="currentColor" 
                        strokeWidth="2" 
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </Popover.Panel>
  );
}
