
import Nav from "../components/Nav"; 
import Footer from '../components/Footer';
import Jobs from '../components/Jobs';


function JobsPage() {
    return (
      <div>
       <Nav />
       <Jobs />
       <Footer />
      </div>
    );
  }
  
  export default JobsPage;
  