import React, { useEffect, useState } from 'react';
import { getProfile, logoutUser, deleteAccount, editProfile, requestLoggedInPasswordReset } from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import { Dialog } from '@headlessui/react';

const UserProfile = () => {
  const [profile, setProfile] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({ name: '', surname: '' });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [password, setPassword] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const profileData = await getProfile();
        setProfile(profileData.data.user);
        setFormData({ name: profileData.data.user.name, surname: profileData.data.user.surname });
      } catch (error) {
        console.error('Error fetching profile:', error);
        setError('Failed to fetch profile information.');
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  const handleLogout = () => {
    logoutUser();
    navigate('/loginpage');
  };

  const handleDeleteAccount = async () => {
    if (!password) {
      setError('Password is required to delete account.');
      return;
    }
  
    try {
      await deleteAccount({ password });
      logoutUser();
      navigate('/registerpage');
    } catch (error) {
      console.error('Error deleting account:', error);
      setError('Failed to delete account. Make sure your password is correct.');
    } finally {
      setModalOpen(false);
    }
  };

  const handleEditProfile = async () => {
    try {
      await editProfile(formData); 
      setProfile({ ...profile, ...formData });
      setEditMode(false);
      setSuccessMessage('Profile updated successfully');
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (error) {
      console.error('Error editing profile:', error);
      setError('Failed to edit profile.');
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const openDeleteModal = () => {
    setError('');
    setModalOpen(true);
  };

  const handleResetPassword = async () => {
    try {
      await requestLoggedInPasswordReset();
      setSuccessMessage('Password reset link has been sent to your email.');
      setResetPasswordModalOpen(true);
      setError('');
    } catch (error) {
      setError('Failed to send password reset link.');
      setSuccessMessage('');
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-[#53A751] border-t-transparent"></div>
      </div>
    );
  }

  if (error && !modalOpen) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white flex items-center justify-center">
        <div className="bg-red-50 p-4 rounded-lg">
          <p className="text-red-600 text-center">{error}</p>
        </div>
      </div>
    );
  }

  if (!profile) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white flex items-center justify-center">
        <div className="text-gray-500 text-center">No profile data available.</div>
      </div>
    );
  }

  return (
    <div className="bg-gradient-to-b from-gray-50 to-white min-h-screen">
      <header className="bg-white border-b border-gray-200 px-4 py-6">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-2xl font-bold text-gray-900">My Profile</h1>
        </div>
      </header>

      <main className="mx-auto max-w-4xl py-8 px-4 sm:px-6 lg:px-8">
        {/* Success Message */}
        {successMessage && (
          <div className="mb-6 p-4 bg-green-50 rounded-lg">
            <p className="text-green-600 text-center">{successMessage}</p>
          </div>
        )}

        {/* Profile Card */}
        <div className="bg-white rounded-2xl shadow-lg overflow-hidden">
          <div className="px-6 py-8 border-b border-gray-200">
            <div className="flex items-center space-x-4">
              <div className="h-16 w-16 rounded-full bg-[#53A751] flex items-center justify-center">
                <span className="text-2xl font-semibold text-white">
                  {profile?.name?.[0]}{profile?.surname?.[0]}
                </span>
              </div>
              <div>
                <h2 className="text-xl font-semibold text-gray-900">
                  {profile?.name} {profile?.surname}
                </h2>
                <p className="text-sm text-gray-500">{profile?.email}</p>
              </div>
            </div>
          </div>

          <div className="px-6 py-6">
            <div className="space-y-6">
              {/* Name Field */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Name</label>
                {editMode ? (
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#53A751] focus:border-transparent transition-all"
                  />
                ) : (
                  <p className="text-gray-900 py-2">{profile.name}</p>
                )}
              </div>

              {/* Surname Field */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Surname</label>
                {editMode ? (
                  <input
                    type="text"
                    name="surname"
                    value={formData.surname}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#53A751] focus:border-transparent transition-all"
                  />
                ) : (
                  <p className="text-gray-900 py-2">{profile.surname}</p>
                )}
              </div>

              {/* Email Field */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Email</label>
                <p className="text-gray-900 py-2">{profile.email}</p>
              </div>

              {/* Edit Button */}
              <div className="flex justify-end">
                {editMode ? (
                  <button
                    onClick={handleEditProfile}
                    className="px-6 py-2 bg-[#53A751] text-white rounded-lg hover:bg-[#458b43] transition-colors duration-200"
                  >
                    Save Changes
                  </button>
                ) : (
                  <button
                    onClick={() => setEditMode(true)}
                    className="px-6 py-2 border border-[#53A751] text-[#53A751] rounded-lg hover:bg-[#53A751] hover:text-white transition-colors duration-200"
                  >
                    Edit Profile
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Account Management Card */}
        <div className="mt-8 bg-white rounded-2xl shadow-lg overflow-hidden">
          <div className="px-6 py-6 border-b border-gray-200">
            <h3 className="text-lg font-semibold text-gray-900">Account Management</h3>
          </div>

          <div className="p-6 space-y-6">
            {/* Password Reset Section */}
            <div className="flex items-center justify-between">
              <div>
                <h4 className="font-medium text-gray-900">Reset Password</h4>
                <p className="text-sm text-gray-500 mt-1">
                  Send a password reset link to your email
                </p>
              </div>
              <button
                onClick={handleResetPassword}
                className="px-6 py-2 bg-white border border-[#53A751] text-[#53A751] rounded-lg hover:bg-[#53A751] hover:text-white transition-colors duration-200"
              >
                Reset Password
              </button>
            </div>

            {/* Logout Section */}
            <div className="flex items-center justify-between pt-6 border-t border-gray-200">
              <div>
                <h4 className="font-medium text-gray-900">Logout</h4>
                <p className="text-sm text-gray-500 mt-1">
                  End your current session
                </p>
              </div>
              <button
                onClick={handleLogout}
                className="px-6 py-2 bg-white border border-red-500 text-red-500 rounded-lg hover:bg-red-500 hover:text-white transition-colors duration-200"
              >
                Logout
              </button>
            </div>

            {/* Delete Account Section */}
            <div className="flex items-center justify-between pt-6 border-t border-gray-200">
              <div>
                <h4 className="font-medium text-gray-900">Delete Account</h4>
                <p className="text-sm text-gray-500 mt-1">
                  Permanently delete your account and all data
                </p>
              </div>
              <button
                onClick={openDeleteModal}
                className="px-6 py-2 bg-white border border-red-500 text-red-500 rounded-lg hover:bg-red-500 hover:text-white transition-colors duration-200"
              >
                Delete Account
              </button>
            </div>
          </div>
        </div>

        {error && (
          <div className="mt-6 p-4 bg-red-50 rounded-lg">
            <p className="text-red-600 text-center">{error}</p>
          </div>
        )}
      </main>

      {/* Delete Account Modal */}
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)} className="relative z-50">
        <div className="fixed inset-0 bg-black/30 backdrop-blur-sm" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 shadow-xl transition-all">
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0">
                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                  </svg>
                </div>
              </div>
              <div className="flex-1">
                <Dialog.Title className="text-lg font-medium text-gray-900">
                  Delete Account
                </Dialog.Title>
                <Dialog.Description className="mt-2 text-sm text-gray-500">
                  This action cannot be undone. All your data will be permanently deleted.
                </Dialog.Description>
              </div>
            </div>

            <div className="mt-4">
              <input
                type="password"
                placeholder="Enter your password to confirm"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-500 focus:border-transparent transition-all"
              />
            </div>

            {error && (
              <p className="mt-2 text-sm text-red-600">{error}</p>
            )}

            <div className="mt-6 flex justify-end space-x-3">
              <button
                type="button"
                onClick={() => setModalOpen(false)}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleDeleteAccount}
                className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                Delete Account
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      {/* Reset Password Success Modal */}
      <Dialog open={resetPasswordModalOpen} onClose={() => setResetPasswordModalOpen(false)} className="relative z-50">
        <div className="fixed inset-0 bg-black/30 backdrop-blur-sm" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 shadow-xl transition-all">
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0">
                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                </div>
              </div>
              <div className="flex-1">
                <Dialog.Title className="text-lg font-medium text-gray-900">
                  Password Reset Email Sent
                </Dialog.Title>
                <Dialog.Description className="mt-2 text-sm text-gray-500">
                  Please check your email inbox for instructions to reset your password.
                </Dialog.Description>
              </div>
            </div>

            <div className="mt-6 flex justify-end">
              <button
                type="button"
                onClick={() => setResetPasswordModalOpen(false)}
                className="px-4 py-2 text-sm font-medium text-white bg-[#53A751] rounded-lg hover:bg-[#458b43] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#53A751]"
              >
                Close
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
};

export default UserProfile;
