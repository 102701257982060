export default function Article1() {
    return (
      <div className="overflow-hidden bg-gray-50 py-12 sm:py-24">
        <div className="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
          <div className="max-w-4xl">
            <p className="text-base/7 font-semibold text-[#4d7f6e]">Dreirosen Apotheke</p>
            <h1 className="mt-2 text-pretty text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
              Saisonale Wellnesstipps: Gesundheit und Achtsamkeit im Winter
            </h1>
            <p className="mt-6 text-balance text-xl/8 text-gray-700">
              Mit weniger Sonnenlicht am Tag sinken unsere Aktivitäten. Wenn die Aktivität sinkt, verringert sich aber auch unsere kcal—Bilanz.
            </p>
          </div>
          <section className="mt-10 grid grid-cols-1 lg:grid-cols-2 lg:gap-x-8 lg:gap-y-16">
            <div className="lg:pr-8">
              <h2 className="text-pretty text-2xl font-semibold tracking-tight text-gray-900">Anpassung an saisonale Veränderungen</h2>
              <p className="mt-6 text-base/7 text-gray-600">
                Für viele Menschen ist die Herbst- und Winterzeit mit mehr Trägheit verbunden. Weniger Aktivität ist vorhanden, da die Dunkelheit uns oft am Morgen zur Arbeit begleitet und am Abend wieder in Empfang nimmt. Die Natur bereitet sich auf den Winter vor – und dies sollten auch wir tun. Die Zeit des Saisonwechsels ist wichtig für den Körper, sodass sich das Immunsystem auf die Kälte der kommenden Monate einstellen kann.
              </p>
              <p className="mt-8 text-base/7 text-gray-600">
                Immer wieder hören wir von unseren Kunden: “Ach, ich weiss nicht, ob die kommende Zeit der richtige Zeitpunkt ist, um an eine Ernährungsumstellung zu denken.”, “Wann ist der richtige Zeitpunkt?” Diesen gibt es nicht. Es ist zu jeder Jahreszeit ein spannendes Thema, seinem Körper mehr Bewusstsein zu schenken.
              </p>
              <p className="mt-8 text-base/7 text-gray-600">
                Es sind viele kleine Schritte im Alltag, die zum Ziel führen. Einige alte Gewohnheiten loslassen, zu Gunsten von neuen, angepassten Handlungen. Was vor 3 Jahren noch stimmig war, ist im Heute eventuell nicht mehr angebracht.
              </p>
            </div>
            <div className="pt-5 lg:row-span-2 lg:-mr-16 xl:mr-auto">
              <div className="-mx-8 grid grid-cols-2 gap-4 sm:-mx-16 sm:grid-cols-4 lg:mx-0 lg:grid-cols-2 lg:gap-4 xl:gap-8">
                <div className="aspect-square overflow-hidden rounded-xl shadow-lg hover:shadow-2xl transition-shadow duration-300">
                  <img
                    alt=""
                    src="images/foodimg1.jpg"
                    className="block w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
                  />
                </div>
                <div className="aspect-square overflow-hidden rounded-xl shadow-lg hover:shadow-2xl transition-shadow duration-300 lg:-mt-40">
                  <img
                    alt=""
                    src="images/foodimg4.jpg"
                    className="block w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
                  />
                </div>
                <div className="aspect-square overflow-hidden rounded-xl shadow-lg hover:shadow-2xl transition-shadow duration-300">
                  <img
                    alt=""
                    src="images/foodimg2.jpg"
                    className="block w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
                  />
                </div>
                <div className="aspect-square overflow-hidden rounded-xl shadow-lg hover:shadow-2xl transition-shadow duration-300 lg:-mt-40">
                  <img
                    alt=""
                    src="images/foodimg3.jpg"
                    className="block w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
                  />
                </div>
              </div>
            </div>
          </section>
          <div className="mt-10 bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-pretty text-2xl font-semibold tracking-tight text-gray-900">
              Bewusst Pausen einlegen
            </h2>
            <p className="mt-4 text-base/7 text-gray-600">
              Planen Sie Pausen für Bewegung fix in Ihren Terminkalender ein. In der kalten Jahreszeit ist das Bewegen an der frischen Luft eine große Unterstützung, um das Immunsystem zu stärken. Eine aktive Pause entspannt auch das Nervensystem.
            </p>
          </div>
          <blockquote className="mt-10 border-l-4 border-[#3B725C] pl-4 font-semibold text-gray-900 bg-gray-50 rounded-md shadow-md p-4">
            <p>
              “Vergessen sie Verbote, setzen sie sich realistische Ziele, zum Beispiel durch Wahrnehmen der eigenen Körpersignale.”
            </p>
          </blockquote>
          <div className="mt-10 bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-pretty text-2xl font-semibold tracking-tight text-gray-900">
              Die Mahlzeit vor “DER MAHLZEIT”
            </h2>
            <p className="mt-4 text-base/7 text-gray-600">
              Die Meinung, dass es gut sei, an einem Tag, an dem ein spezielles Essen bevorsteht, nicht zu essen, ist falsch! Unser Körper braucht auch an solchen Tagen ein ausgewogenes Frühstück. Am Mittag eine kleine leichte Mahlzeit mit Gemüse und Fleisch oder Fisch, wenig Getreideanteil. So bleibt der Körper im «Verbrennungsmodus» und kann dann auch das Abendessen gut verarbeiten. Trinken Sie genug Wasser, um die Verdauungssäfte zu unterstützen.
            </p>
          </div>
          <div className="mt-10 bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-pretty text-2xl font-semibold tracking-tight text-gray-900">
              Mami’s Guetzli
            </h2>
            <p className="mt-4 text-base/7 text-gray-600">
              «Guezlizeit» ein Genuss, der dazu gehört. Guezli liefern viel Zucker und Fett, meist sind sie mit Weissmehl hergestellt, was für unseren Körper schnelle Energielieferanten sind, doch auch gerne übersäuern oder den Darm träge werden lassen. Die zusätzlichen Kalorien sind vielen nicht bewusst. Wenn Sie sich Backwaren gönnen, planen Sie diese direkt nach dem Mittagessen ein. In der Hauptmahlzeit bewusst die stärkehaltigen Nahrungsmittel reduzieren oder gar weglassen, da diese im Gebackenen erscheinen.
            </p>
          </div>
          <div className="mt-10 bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-pretty text-3xl font-semibold tracking-tight text-gray-900">
              Kleine Alltagshelfer, die helfen können
            </h2>
            <h2 className="mt-10 text-pretty text-2xl font-semibold tracking-tight text-gray-900">
              Weleda Amara-Tropfen
            </h2>
            <p className="mt-4 text-base/7 text-gray-600">
              Lindern Verdauungsbeschwerden wie Sodbrennen, Blähungen und Völlegefühl. Die Frischpflanzen-Mischung regt u.a. die Magensaftsekretion an und fördert die Gallenbildung und -absonderung und damit die Fettverdauung.
            </p>
            <h2 className="mt-10 text-pretty text-2xl font-semibold tracking-tight text-gray-900">
              Pfarrer Künzle Mariendistel – Kapseln
            </h2>
            <p className="mt-4 text-base/7 text-gray-600">
              Mariendistel ist die einzige Pflanze in Europa, welche ab einer gewissen Menge Silymarin (Inhaltsstoff der Mariendistelfrüchte / Cardui mariae fructus) hepatoprotektiv wirkt. Dies bedeutet, dass Leberzellen geschützt und wieder aufgebaut werden können. In geringeren Dosen wirken die Kapseln fettverdauungsfördernd und helfen bei Verdauungsbeschwerden.
            </p>
            <h2 className="mt-10 text-pretty text-2xl font-semibold tracking-tight text-gray-900">
              Equi / Nutrexin Basenbad
            </h2>
            <p className="mt-4 text-base/7 text-gray-600">
              Durch die aufgenommenen Säuren in Blätterteig, Alkohol und Fleischwaren verändert sich auch unser Hautbild. Basische Mineralsalze gemischt mit einem duftigen ätherischen Öl helfen, Ihre Hautbarrieren und deren Funktion zu erhalten.
            </p>
          </div>
        </div>
      </div>
    )
  }
  