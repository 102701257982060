import { useState, useEffect } from 'react';
import { fetchFavorites, toggleFavoriteProduct } from '../services/apiService';
import { useNavigate } from 'react-router-dom';

export default function UserFavorites() {
  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const getFavorites = async () => {
      try {
        const response = await fetchFavorites();
        setFavorites(response.data.favorites);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching favorites:', error);
        setLoading(false);
      }
    };
    getFavorites();
  }, []);

  const handleViewProduct = (pharmacode) => {
    navigate(`/product/${pharmacode}`);
  };

  const handleToggleFavorite = async (pharmacode) => {
    try {
      await toggleFavoriteProduct(pharmacode);
      setFavorites((prevFavorites) =>
        prevFavorites.filter((product) =>
          product.articles.every((article) => article.pharmacode !== pharmacode)
        )
      );
    } catch (error) {
      console.error('Error toggling favorite:', error);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <svg
          className="animate-spin h-10 w-10 text-[#3A7861]"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8v8H4z"
          ></path>
        </svg>
        <span className="ml-2 text-gray-500">Loading favorites...</span>
      </div>
    );
  }

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 pb-6 pt-12">Your Favorite Products</h1>

        <section aria-labelledby="favorites-heading" className="pb-24 pt-6">
          <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4">
            {favorites.length === 0 ? (
              <p className="text-lg text-gray-500">You have no favorite products yet.</p>
            ) : (
              favorites.map((product) =>
                product.articles.map((article) => (
                  <div
                    key={article.pharmacode}
                    onClick={() => handleViewProduct(article.pharmacode)}
                    className="group relative border border-gray-200 rounded-lg bg-white shadow-sm p-4 flex flex-row lg:flex-col hover:shadow-lg transition-shadow duration-200 cursor-pointer"
                  >
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleToggleFavorite(article.pharmacode);
                      }}
                      className="absolute top-2 right-2"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-[#e05257]" viewBox="0 0 24 24" fill="currentColor">
                        <path fillRule="evenodd" d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/>
                      </svg>
                    </button>

                    {/* Image section */}
                    <div className="flex-shrink-0 w-32 h-32 mr-4 lg:w-full lg:h-64 lg:mb-4">
                      {article.hasPhoto && article.images && article.images.M?.length > 0 ? (
                        <img
                          src={`https://pharma-outlet.com/${article.images.M[0].replace(/\\/g, '/')}`}
                          alt={article.description?.description || 'No description'}
                          className="h-full w-full object-contain"
                        />
                      ) : (
                        <div className="h-full w-full flex items-center justify-center text-gray-500">
                          <span>No Image Available</span>
                        </div>
                      )}
                    </div>

                    {/* Product details section */}
                    <div className="flex-grow flex flex-col justify-between text-left lg:text-center">
                      <div>
                        <h3 className="text-sm font-medium text-gray-900">
                          {article.description?.description || 'Bschrieb nid verfüegbar'}
                        </h3>
                        <p className="italic text-gray-500 line-clamp-2">
                          {article.description?.longDescription || 'Detail nid verfüegbar'}
                        </p>
                        {article.prices?.price ? (
                          <p className="mt-2 font-medium text-gray-900">
                            {article.prices.price} CHF
                          </p>
                        ) : (
                          <p className="mt-2 font-medium text-gray-900">Pris nid verfüegbar</p>
                        )}
                      </div>

                      {/* Regulation-based shipping information */}
                      {article.regulations?.length > 0 && (
                        <div className="mt-2 text-sm flex items-center justify-start lg:justify-center">
                          {article.regulations[0].code.id === 'Sale.Web.Rx' && (
                            <div className="flex items-center">
                              <p className="text-[#E05257]">Shipping not possible</p>
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1 text-[#E05257]" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                <path d="M3 3.75A.75.75 0 013.75 3h16.5a.75.75 0 01.75.75v2.5a.75.75 0 01-.75.75h-16.5a.75.75 0 01-.75-.75v-2.5zM4.5 10.5a.75.75 0 01.75-.75h13.5a.75.75 0 01.75.75v7.25a.75.75 0 01-.75.75H5.25a.75.75 0 01-.75-.75V10.5z" />
                              </svg>
                            </div>
                          )}
                          {article.regulations[0].code.id === 'Sale.Web.CC' && (
                            <div className="flex items-center">
                              <p className="text-[#E05257]">Shipping not possible</p>
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1 text-[#E05257]" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                <path d="M3 3.75A.75.75 0 013.75 3h16.5a.75.75 0 01.75.75v2.5a.75.75 0 01-.75.75h-16.5a.75.75 0 01-.75-.75v-2.5zM4.5 10.5a.75.75 0 01.75-.75h13.5a.75.75 0 01.75.75v7.25a.75.75 0 01-.75.75H5.25a.75.75 0 01-.75-.75V10.5z" />
                              </svg>
                            </div>
                          )}
                          {article.regulations[0].code.id === 'Sale.Web.OK' && (
                            <div className="flex items-center">
                              <p className="text-[#4D7F6E]">Shipping possible</p>
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1 text-[#4D7F6E]" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                <path d="M3 3.75A.75.75 0 013.75 3h16.5a.75.75 0 01.75.75v2.5a.75.75 0 01-.75.75h-16.5a.75.75 0 01-.75-.75v-2.5zM4.5 10.5a.75.75 0 01.75-.75h13.5a.75.75 0 01.75.75v7.25a.75.75 0 01-.75.75H5.25a.75.75 0 01-.75-.75V10.5z" />
                              </svg>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                ))
              )
            )}
          </div>
        </section>
      </div>
    </div>
  );
}
