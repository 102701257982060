import { useState, useEffect } from 'react';
import { fetchCategories } from '../services/apiService'; 
import { useNavigate } from 'react-router-dom';

export default function CategoryList() {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const getCategories = async () => {
      try {
        const response = await fetchCategories(); // Fetch categories from the backend
        setCategories(response.data); // Assume the data structure from Postman
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    getCategories();
  }, []);

  const handleSubcategoryClick = (subcategoryId) => {
    const cleanSubcategoryId = subcategoryId.trim();
    navigate(`/categories/${cleanSubcategoryId}/products`, { replace: true });
  };

  return (
    <div className="bg-white">
      <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-6">
        <h1 className="text-4xl font-bold tracking-tight text-gray-900">Categories</h1>
        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3">
          {categories.map((category) => (
            <div key={category._id} className="group relative border border-gray-200 rounded-lg p-6 bg-white shadow-sm">
              <h3 className="text-lg font-semibold text-gray-900">{category.category.en}</h3>
              <ul className="mt-4 space-y-2">
                {category.subcategories.map((subcategory) => (
                  <li key={subcategory.en}>
                    <button
                      onClick={() => handleSubcategoryClick(subcategory.en)}
                      className="text-sm text-indigo-600 hover:text-indigo-900"
                    >
                      {subcategory.en}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </main>
    </div>
  );
}
