import { useEffect, useRef } from 'react';

const posts = [
  {
    title: 'Saisonale Wellnesstipps: Gesundheit und Achtsamkeit im Winter',
    href: '/fasten-konvertiert',
    category: { name: 'Dreirosen Apotheke'},
    description:
      'Für viele Menschen ist die Herbst- und Winterzeit mit mehr Trägheit verbunden. Weniger Aktivität ist vorhanden, da die Dunkelheit uns oft am Morgen zur Arbeit begleitet und am Abend wieder in Empfang nimmt...',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
    imageUrl:
      'images/foodimg3.jpg',
  },
  {
    title: 'ENIGMA SCHLAF',
    href: '/enigma-schlaf',
    category: { name: 'Dreirosen Apotheke'},
    description:
      'Schlafmangel macht, schreibt Peter Spork, nicht nur krank, sondern auch dumm. Denn eine der Hauptaufgaben des Schlafes scheint darin zu bestehen, am Tag Gelerntes dauerhaft zu speichern. Die Forscher interessierten sich dabei vor allem für den Tiefschlaf...',
    date: 'Mar 10, 2020',
    datetime: '2020-03-10',
    imageUrl:
      'images/sleepimg3.jpg',
  },
  {
    title: 'Wie komplett ist Ihre Reiseapotheke?',
    href: 'Ferien-konvertiert',
    category: { name: 'Dreirosen Apotheke'},
    description:
      'Bitte prüfen Sie Ihre Reiseapotheke anhand unserer Checkliste. Darin finden Sie eine Auswahl an Produkten, welche wir Ihnen für Ihre Reise empfehlen. Bei Fragen oder zur kostenlosen Zusammenstellung stehen wir Ihnen natürlich gerne zur Seite...',
    date: 'Feb 12, 2020',
    datetime: '2020-02-12',
    imageUrl:
      'images/travelimg1.jpg',
  },
];

export default function BlogSection() {
  const boxesRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, index) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate-fade-in-up');
            entry.target.style.animationDelay = `${index * 0.2}s`; // Delay for each box
          } else {
            entry.target.classList.remove('animate-fade-in-up');
            entry.target.style.animationDelay = '';
          }
        });
      },
      { threshold: 0.1 }
    );

    boxesRef.current.forEach((box) => observer.observe(box));

    return () => observer.disconnect();
  }, []);

  return (
    <div className="relative px-6 lg:px-8 lg:pb-28 lg:pt-10">
      <div className="absolute inset-0">
        <div className="h-1/3 sm:h-2/3" />
      </div>
      <div className="relative mx-auto max-w-7xl">
        <div className="text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Gesundheitsartikel</h2>
          <p className="mx-auto mt-3 max-w-2xl text-xl text-gray-500 sm:mt-4">
          Hier finden Sie einige Gesundheitsartikel, die Ihnen helfen, Ihr Wohlbefinden zu fördern und mehr über verschiedene Aspekte der Gesundheit und des Lebensstils zu erfahren.
          </p>
        </div>
        <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
        {posts.map((post, index) => (
            <a
              key={post.title}
              href={post.href}
              className="flex flex-col overflow-hidden rounded-lg shadow-lg opacity-0 transform transition-opacity duration-500 ease-in-out"
              ref={(el) => (boxesRef.current[index] = el)}
            >
              <div className="flex-shrink-0">
                <img alt="" src={post.imageUrl} className="h-48 w-full object-cover" />
              </div>
              <div className="flex flex-1 flex-col justify-between bg-white p-6">
                <div className="flex-1">
                  <p className="text-sm font-medium text-[#53a751]">
                    <span>{post.category.name}</span>
                  </p>
                  <p className="mt-2 text-xl font-semibold text-gray-900">{post.title}</p>
                  <p className="mt-3 text-base text-gray-500">{post.description}</p>
                </div>
              </div>
            </a>
          ))}

        </div>
      </div>
      <style jsx>{`
        @keyframes fade-in-up {
          0% {
            opacity: 0;
            transform: translateY(20px);
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .animate-fade-in-up {
          animation: fade-in-up 0.5s ease-in-out forwards;
        }
      `}</style>
    </div>
  );
}