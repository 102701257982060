export default function Article3() {
    return (
      <div className="overflow-hidden bg-gray-50 py-12 sm:py-24">
        <div className="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
          <div className="max-w-4xl">
            <p className="text-base/7 font-semibold text-[#4d7f6e]">Dreirosen Apotheke</p>
            <h1 className="mt-2 text-pretty text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
            Wie komplett ist Ihre Reiseapotheke?
            </h1>
            <p className="mt-6 text-balance text-xl/8 text-gray-700">
            Bitte prüfen Sie Ihre Reiseapotheke anhand unserer Checkliste.
            Darin finden Sie eine Auswahl an Produkten, welche wir Ihnen
            für Ihre Reise empfehlen. Bei Fragen oder zur kostenlosen
            Zusammenstellung stehen wir Ihnen natürlich gerne zur Seite.
            </p>
          </div>
          <section className="mt-2 grid grid-cols-1 lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
            <div className="lg:pr-8">
              <p className="mt-6 text-base/7 text-gray-600">
              Die Auswahl ist für Kinder wie Erwachsene gedacht und kann
                natürlich mit anderen Medikamenten-Marken ausgetauscht
                werden.
              </p>
              <p className="mt-8 text-base/7 text-gray-600">
              Viele Dinge können Sie spontan vor Ort in jeder guten
                Apotheke oder sogar in einem normalen Einkaufsladen
                besorgen. Jedoch macht es immer Sinn, eine sorgfältig
                vorbereite Erste Hilfe – Box jederzeit zur Hand zu haben.
              </p>
              <h2 className="mt-10 text-pretty text-2xl font-semibold tracking-tight text-gray-900">
                Unsere Empfehlung für deren Grundinhalt
            </h2>
            <ul className="mt-4 space-y-2 text-base/7 text-gray-600 list-disc list-inside">
                <li>Sackmesser (mit sauberer Klinge und integrierter Schere)</li>
                <li>Eine Rolle Verbandsgaze (für Verstauchungen und Schnittwunden, zum Beispiel am Fuß durch Muscheln)</li>
                <li>Wasserdichtes Pflaster (dasjenige zum Schneiden)</li>
                <li>Ein kleines Fläschchen PVC-Jod zur Desinfektion</li>
            </ul>
            <h2 className="mt-10 text-pretty text-2xl font-semibold tracking-tight text-gray-900">
                Empfehlungen bei Reisebeschwerden
            </h2>
            <h3 className="mt-5 text-lg font-semibold text-gray-900">Durchfall</h3>
                <p className="mt-2 text-base/7 text-gray-600">
                <strong>Lactoferment Sachet:</strong> Anders als Imodium-Produkte bringen diese Milchsäurekulturen Ihren Darm wieder auf Kurs.
                </p>
                <p className="mt-2 text-base/7 text-gray-600">
                <strong>Bioflorina Kapseln:</strong> Verdauungsstärkende Bakterienkulturen – damit ist Streetfood kein Problem mehr.
                </p>
                <h3 className="mt-5 text-lg font-semibold text-gray-900">Erbrechen</h3>
                    <p className="mt-2 text-base/7 text-gray-600">
                    <strong>Itinerol B6 Dragées:</strong> Bei Erbrechen oder Übelkeit – Ferien sollte man unbeschwert geniessen können.
                    </p>
                    <h3 className="mt-5 text-lg font-semibold text-gray-900">Verstopfung</h3>
                    <p className="mt-2 text-base/7 text-gray-600">
                    <strong>Dulcolax Picosulfat Tropfen:</strong> Mittels den Tropfen kann das Produkt optimal in der ganzen Familie angewendet werden – vom Kind bis zum Grossvater.
                    </p>
            </div>
            <div className="pt-5 lg:row-span-2 lg:-mr-16 xl:mr-auto">
              <div className="-mx-8 grid grid-cols-2 gap-4 sm:-mx-16 sm:grid-cols-4 lg:mx-0 lg:grid-cols-2 lg:gap-4 xl:gap-8">
                <div className="aspect-square overflow-hidden rounded-xl shadow-lg hover:shadow-2xl transition-shadow duration-300">
                  <img
                    alt=""
                    src="images/travelimg1.jpg"
                    className="block w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
                  />
                </div>
                <div className="aspect-square overflow-hidden rounded-xl shadow-lg hover:shadow-2xl transition-shadow duration-300 lg:-mt-40">
                  <img
                    alt=""
                    src="images/travelimg2.jpg"
                    className="block w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
                  />
                </div>
                <div className="aspect-square overflow-hidden rounded-xl shadow-lg hover:shadow-2xl transition-shadow duration-300">
                  <img
                    alt=""
                    src="images/travelimg3.jpg"
                    className="block w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
                  />
                </div>
                <div className="aspect-square overflow-hidden rounded-xl shadow-lg hover:shadow-2xl transition-shadow duration-300 lg:-mt-40">
                  <img
                    alt=""
                    src="images/travelimg4.jpg"
                    className="block w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
                  />
                </div>
              </div>
            </div>
            
          </section>
          <div className="">
                    <h3 className="mt-5 text-lg font-semibold text-gray-900">Verstopfte Nase</h3>
                    <p className="mt-2 text-base/7 text-gray-600">
                    <strong>Triofan / Otrivin:</strong> Wirken abschwellend – und schon ist der Ohrendruck auf dem Flug weg.
                    </p>
                    <p className="mt-2 text-base/7 text-gray-600">
                    <strong>Triomer Salzspray:</strong> Zur optimalen Befeuchtung Ihrer Nasenschleimhäute auf einem langen Flug oder in der Wüste.
                    </p>
                    <h3 className="mt-5 text-lg font-semibold text-gray-900">Reise-Übelkeit</h3>
                    <p className="mt-2 text-base/7 text-gray-600">
                    <strong>Zintona Kapseln:</strong> Ingwer-Kapseln helfen Ihnen auch die hintersten Täler mit dem Bus zu erreichen und die Bootsausfahrt auf dem Meer zu geniessen.
                    </p>
                    <h3 className="mt-5 text-lg font-semibold text-gray-900">Halsweh</h3>
                    <p className="mt-2 text-base/7 text-gray-600">
                    <strong>Lidazon Spray:</strong>Mittels der dreifach-Wirkung (schmerzstillend, desinfizierend
                    und antibakteriell) müssen Sie nicht auf leckeres Essen und
                    kühle Cocktails verzichten
                    </p>
                    <h3 className="mt-5 text-lg font-semibold text-gray-900">Schmerzen</h3>
                    <p className="mt-2 text-base/7 text-gray-600">
                    <strong>Paracetamol / Ibuprofen</strong>Schnelle Wirkung, einfach einzunehmen – schicken Sie die
                    Kopfschmerzen wieder nach Hause
                    </p>
                    <h3 className="mt-5 text-lg font-semibold text-gray-900">Sonnenbrand</h3>
                    <p className="mt-2 text-base/7 text-gray-600">
                    <strong>Avène / Louis Widmer Aprés Soleil Lotion:</strong>Bei Sonnenbränden beachten Sie bitte die Regel: befeuchten,
                    befeuchten, befeuchten – Ihre Haut wird Ihnen eine gute
                    Körperlotion danken.
                    </p>
                </div>
        </div>
      </div>
    )
  }
  