import Nav from "../components/Nav"; 
import Footer from '../components/Footer';
import AboutUs from '../components/AboutUs';
import Banner from "../components/Banner";


function AboutUsPage() {
    return (
      <div>
      <Banner />
       <Nav />
       <AboutUs />
       <Footer />
      </div>
    );
  }
  
  export default AboutUsPage;
  