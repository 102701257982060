import Nav from "../components/Nav"; 
import Footer from '../components/Footer';
import Banner from '../components/Banner';
import Artcile3 from "../components/HealthArticles/Article3";


function ArticlePage3() {
    return (
      <div>
      <Banner />
       <Nav />
       <Artcile3 />
       <Footer />
      </div>
    );
  }
  
  export default ArticlePage3;
  