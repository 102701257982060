import { useEffect, useRef } from 'react';

const timeline = [
  {
    name: '+100,000 Produkte',
    description:
      'Ob für Gsundheit, Schönheits- oder Pflegeprodukte – bi eus findisch immer, was du bruchsch.',
    date: 'Shop',
    icon: () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 16 16"
        fill="currentColor"
        className="text-[#E05257] hover:scale-110 transition-transform duration-300 ease-in-out"
      >
        <path d="M0 1H15V10H4.60087L4.17982 12H12C13.1046 12 14 12.8954 14 14C14 15.1046 13.1046 16 12 16C10.8954 16 10 15.1046 10 14H6C6 15.1046 5.10457 16 4 16C2.89543 16 2 15.1046 2 14V12.6459L2.98262 7.97846L2.15287 3H0V1Z" />
      </svg>
    ),
  },
  {
    name: 'Schnell und Zuverlässig',
    description:
      'Sicheri und schnelle Diagnosen – vertraue uf üsere Kompetenz i de Labor Diagnostik.',
    date: 'Labor Diagnostik',
    icon: () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="text-[#E05257] hover:scale-110 transition-transform duration-300 ease-in-out"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.55879 3.6972C10.7552 2.02216 13.2447 2.02216 14.4412 3.6972L14.6317 3.96387C14.8422 4.25867 15.1958 4.41652 15.5558 4.37652L16.4048 4.28218C18.3156 4.06988 19.9301 5.68439 19.7178 7.59513L19.6235 8.44415C19.5835 8.8042 19.7413 9.15774 20.0361 9.36831L20.3028 9.55879C21.9778 10.7552 21.9778 13.2447 20.3028 14.4412L20.0361 14.6317C19.7413 14.8422 19.5835 15.1958 19.6235 15.5558L19.7178 16.4048C19.9301 18.3156 18.3156 19.9301 16.4048 19.7178L15.5558 19.6235C15.1958 19.5835 14.8422 19.7413 14.6317 20.0361L14.4412 20.3028C13.2447 21.9778 10.7553 21.9778 9.55879 20.3028L9.36831 20.0361C9.15774 19.7413 8.8042 19.5835 8.44414 19.6235L7.59513 19.7178C5.68439 19.9301 4.06988 18.3156 4.28218 16.4048L4.37652 15.5558C4.41652 15.1958 4.25867 14.8422 3.96387 14.6317L3.6972 14.4412C2.02216 13.2447 2.02216 10.7553 3.6972 9.55879L3.96387 9.36831C4.25867 9.15774 4.41652 8.8042 4.37652 8.44414L4.28218 7.59513C4.06988 5.68439 5.68439 4.06988 7.59513 4.28218L8.44415 4.37652C8.8042 4.41652 9.15774 4.25867 9.36831 3.96387L9.55879 3.6972ZM15.7071 9.29289C16.0976 9.68342 16.0976 10.3166 15.7071 10.7071L11.8882 14.526C11.3977 15.0166 10.6023 15.0166 10.1118 14.526L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929C8.68342 10.9024 9.31658 10.9024 9.70711 11.2929L11 12.5858L14.2929 9.29289C14.6834 8.90237 15.3166 8.90237 15.7071 9.29289Z"
        />
      </svg>
    ),
  },
  {
    name: 'Dini persönligi Apotheke',
    description:
      'Individuell uf dii Bedürfnisse agpasst: Mit personalisierte Vorschläg und schnällere Bestellprozess.',
    date: 'Personalisierti Gsundheitsvorsorg',
    icon: () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        width="48"
        height="48"
        viewBox="0 0 32 32"
        className="text-[#E05257] hover:scale-110 transition-transform duration-300 ease-in-out"
      >
        <path d="M16 15.503A5.041 5.041 0 1 0 16 5.42a5.041 5.041 0 0 0 0 10.083zm0 2.215c-6.703 0-11 3.699-11 5.5v3.363h22v-3.363c0-2.178-4.068-5.5-11-5.5z" />
      </svg>
    ),
  },
  {
    name: 'Spezielle Aktione für dii',
    description:
      'Mit üse regelmässige Rabattä und Sonderaktione chasch du dini Gsundheitsvorsorg günstiger mache.',
    date: 'Super Offerte',
    icon: () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 16 16"
        fill="currentColor"
        className="text-[#E05257] hover:scale-110 transition-transform duration-300 ease-in-out"
      >
        <path d="M3 3V0H5C6.65685 0 8 1.34315 8 3C8 1.34315 9.34315 0 11 0H13V3H16V6H0V3H3Z" />
        <path d="M1 8H7V15H1V8Z" />
        <path d="M15 8H9V15H15V8Z" />
      </svg>
    ),
  },
];

export default function ServicesMobile() {
  const refs = useRef([]);

  useEffect(() => {
    const currentRefs = [...refs.current];

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate-fade-in-up');
          }
        });
      },
      {
        threshold: 0.3,
      }
    );

    currentRefs.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      currentRefs.forEach((ref) => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, []);

  return (
    <div className="bg-white py-16 mt-0">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 flex justify-center items-center">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-12 overflow-hidden lg:mx-0 lg:max-w-none lg:grid-cols-4">
          {timeline.map((item, index) => (
            <div
              key={item.name}
              ref={(el) => (refs.current[index] = el)}
              className="opacity-0 transform translate-y-10 transition-all duration-700 ease-out text-center"
            >
              <time
                dateTime={item.dateTime}
                className="flex justify-center items-center text-md font-semibold leading-8 text-[#E05257]"
              >
                {item.date}
              </time>
              <p className="mt-4 text-lg font-semibold leading-8 tracking-tight text-gray-900">{item.name}</p>
              <p className="mt-2 text-base leading-7 text-gray-600">{item.description}</p>
              <div className="mt-4 flex justify-center">
                <item.icon />
              </div>
            </div>
          ))}
        </div>
      </div>
      <style>{`
        .animate-fade-in-up {
          opacity: 1;
          transform: translateY(0);
        }
      `}</style>
    </div>
  );
}
