import Nav from "../components/Nav"; 
import Footer from '../components/Footer';
import Banner from '../components/Banner';
import Artcile1 from "../components/HealthArticles/Article1";


function ArticlePage1() {
    return (
      <div>
      <Banner />
       <Nav />
       <Artcile1 />
       <Footer />
      </div>
    );
  }
  
  export default ArticlePage1;
  