// src/components/ForgotPassword.js
import React, { useState } from 'react';
import { requestPasswordReset } from '../services/apiService'; // Import the service function

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await requestPasswordReset(email); // Remove the unused response
      setSuccessMessage('Password reset link has been sent to your email.');
      setErrorMessage(''); // Clear any previous errors
    } catch (error) {
      setErrorMessage('Failed to send reset link. Please check your email and try again.');
      setSuccessMessage(''); // Clear any previous success message
    }
  };  

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center py-6 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <div className="bg-white p-8 rounded-lg shadow-lg">
          <h2 className="text-center text-3xl font-extrabold text-gray-900 mb-6">Forgot Password</h2>
          <p className="text-center text-gray-600 mb-6">
            Enter your email below, and we'll send you a password reset link.
          </p>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="rounded-md shadow-sm space-y-4">
              <div>
                <label htmlFor="email" className="sr-only">
                  Email Address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  className="appearance-none rounded-md relative block w-full px-4 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-2 focus:ring-[#436F5E] focus:border-[#436F5E] sm:text-lg"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-3 px-6 border border-transparent text-lg font-medium rounded-md text-white bg-[#436F5E] hover:bg-[#3a5f50] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#436F5E]"
              >
                Send Reset Link
              </button>
            </div>
          </form>

          {successMessage && (
            <p className="text-green-500 text-sm mt-4 text-center">{successMessage}</p>
          )}
          {errorMessage && (
            <p className="text-red-500 text-sm mt-4 text-center">{errorMessage}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
