import React from 'react';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import UserFavorites from '../components/UserFavorites';

function UserFavoritesPage() {
  return (
    <div>
      <Nav />
      <UserFavorites />
      <Footer />
    </div>
  );
}

export default UserFavoritesPage;
