export default function Example() {
  return (
    <div className="bg-gray-50 px-6 py-20 lg:px-8">
      <div className="mx-auto max-w-5xl text-base leading-7 text-gray-700">
        <header className="mb-12 text-center">
          <p className="text-lg font-semibold leading-7 text-[#3B725C]">Dreirosen Apotheke</p>
          <h1 className="mt-3 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
            Dreirosen Apotheke - Mit uns blibts Glaibasel gsund!
          </h1>
        </header>
        <section className="grid grid-cols-1 gap-y-10 lg:grid-cols-2 lg:gap-x-12">
          <div>
            <p className="text-lg leading-8">
              Unser Team nimmt sich gerne Zeit für Sie und Ihre Anliegen. Wir führen ein umfangreiches Sortiment zur täglichen Versorgung und Gesunderhaltung von unseren Quartierbewohnern.
            </p>
            <p className="mt-5">
              Die Dreirosen Apotheke ist eine junge unabhängige Apotheke im Glaibasel. Seit dem Frühling 2014 erfreut sich die alt-eingesessene Drogerie an einem Umbau, neuen Farben und einer integrierten Apotheke. Im Januar 2017 wurde die Lokalität zudem total renoviert, diesmal komplett mit neuem Boden-, Mobiliar- und Lichtkonzept.
            </p>
            <p className="mt-4">
              Frau Ann-Christine Weber hat per August 2022 die leitende Funktion der Dreirosen Apotheke übernommen.
            </p>
          </div>
          <div className="relative flex flex-col items-center lg:items-start">
            <img
              src="images/liveapotheke.jpg"
              alt="Pharmacy online platform"
              className="rounded-xl shadow-lg bg-gray-50 object-cover w-full max-h-80 lg:max-h-full"
            />
            <div className="mt-6">
              <figcaption className="flex gap-x-2 text-sm leading-6 text-gray-600">
                <svg
                  className="mt-0.5 h-5 w-5 flex-none text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13 16h-1v-4h-1m1-4h.01M12 18h.01M21 12c0-4.97-4.03-9-9-9S3 7.03 3 12c0 4.97 4.03 9 9 9s9-4.03 9-9z"
                  />
                </svg>
                Montag - Freitag 08:00 - 19:30 Uhr
              </figcaption>
              <figcaption className="mt-2 flex gap-x-2 text-sm leading-6 text-gray-600">
                <svg
                  className="mt-0.5 h-5 w-5 flex-none text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13 16h-1v-4h-1m1-4h.01M12 18h.01M21 12c0-4.97-4.03-9-9-9S3 7.03 3 12c0 4.97 4.03 9 9 9s9-4.03 9-9z"
                  />
                </svg>
                Samstag 08:00 - 17:00 Uhr, Am Sonntag haben wir geschlossen.
              </figcaption>
            </div>
          </div>
        </section>
        <h2 className="mt-10 text-2xl font-bold tracking-tight text-gray-900">Unsere Geschichte</h2>
        <p className="mt-6">
          Die Dreirosen Apotheke hat eine längere Geschichte im Quartier hinter sich, und es ist aktuell so, dass wir leider die letzte Drogerie im gesamten Glaibasel sind.
        </p>
        <p className="mt-4">
          Mit dem alten Team und einem neuen Auftritt begegnet die Dreirosen Apotheke einem veränderten Gesundheitsmarkt und breiteren Kundenbedürfnissen als noch vor zehn Jahren. Preisdruck auf Medikamente, anspruchsvolle Gestaltung der Einkaufszone und vermehrte Zusatzleistungen gehören zu unseren Stärken.
        </p>
        <blockquote className="mt-12 border-l-4 border-[#3B725C] pl-4 font-semibold text-gray-900 bg-gray-100 rounded-md shadow-md p-4">
          <p>
            “Wir setzen nicht unsere Produkte in den Vordergrund, sondern Sie als Menschen. Wir verstehen uns als Partner für die Erhaltung Ihrer Gesundheit, nicht als Partner kostspieliger Krankheiten.”
          </p>
          <figcaption className="mt-4 text-sm text-[#4D7F6E]">
            <strong>Dreirosen Apotheke</strong>
          </figcaption>
        </blockquote>
        <section className="mt-12">
          <p>
            Per 1. Mai 2015 schlossen wir uns mit der Horburg Apotheke an der Feldbergstrasse zusammen. Herr Leo Freyer, Apotheker und vormaliger Inhaber der Horburg Apotheke, hatte bis Ende 2020 die Fachtechnische Verantwortung in der Dreirosen Apotheke.
          </p>
          <p className="mt-4">
            Im Januar 2017 wurde erneut eine Gesamtrenovierung der Dreirosen durchgeführt, wobei vom Boden bis zur Decke alles neu konzipiert wurde. Seit dahin können wir nun Eigenherstellungen aus unserem Labor sowie mit diskreten Beratunsgesprächen im neuen Beratungsraum unsere Kundschaft noch besser beraten und in Ihrer Gesundheitsplanung unterstützen.
          </p>
        </section>
        <div className="mt-16">
          <img
            src="images/2ndlogo.webp"
            alt="Interior view of Dreirosen Apotheke"
            className="rounded-xl shadow-md bg-gray-100 object-cover w-full"
          />
        </div>
      </div>
    </div>
  );
}
