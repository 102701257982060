import React, { useState, useEffect, useRef } from 'react';
import { FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { searchProducts } from '../services/apiService';

const SearchBar = ({ placeholder = 'Suchen...' }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [previewResults, setPreviewResults] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const searchRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowPreview(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (searchTerm.trim().length >= 2) {
      setIsLoading(true);
      setShowPreview(true);

      const fetchResults = async () => {
        try {
          const response = await searchProducts(searchTerm, 1, 5);
          console.log('Search API Response:', response); // Add this debug log
          if (response?.success && Array.isArray(response.data.products)) {
            console.log('Products:', response.data.products); // Add this debug log
            setPreviewResults(response.data.products);
          }
        } catch (error) {
          console.error('Search preview failed:', error);
          setPreviewResults([]);
        } finally {
          setIsLoading(false);
        }
      };

      const timer = setTimeout(fetchResults, 150);
      return () => clearTimeout(timer);
    } else {
      setPreviewResults([]);
      setShowPreview(false);
    }
  }, [searchTerm]);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      navigate(`/products?search=${encodeURIComponent(searchTerm)}`);
      setShowPreview(false);
    }
  };

  const handlePreviewClick = (pharmacode) => {
    navigate(`/product/${pharmacode}`);
    setShowPreview(false);
    setSearchTerm('');
  };

  const getOptimizedImageUrl = (images) => {
    if (!Array.isArray(images) || images.length === 0) return null;
    
    // Try to find PICFRONT3D first
    const picFront3D = images.find(image => image.includes('PICFRONT3D'));
    if (picFront3D) {
      return picFront3D;
    }
    
    // Then try to find any PICFRONT image
    const picFront = images.find(image => image.includes('PICFRONT'));
    if (picFront) {
      return picFront;
    }
    
    // Fallback to the first image
    return images[0];
  };

  return (
    <div ref={searchRef} className="relative w-full">
      <form onSubmit={handleSearchSubmit} className="relative w-full">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="block w-full bg-gray-50 rounded-full shadow-lg border-gray-100 py-3 pl-4 pr-12 text-sm placeholder-gray-700 focus:border-indigo-500 focus:ring-indigo-500"
          placeholder={placeholder}
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
          <button
            type="submit"
            className="flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none"
          >
            <FaSearch className="h-4 w-4 mr-2" aria-hidden="true" />
          </button>
        </div>
      </form>

      {showPreview && searchTerm.trim().length >= 2 && (
        <div className="absolute z-50 w-full mt-2 bg-white rounded-lg shadow-xl border border-gray-200 max-h-96 overflow-y-auto">
          {isLoading ? (
            <div className="p-4 text-center text-gray-500">Loading...</div>
          ) : previewResults.length > 0 ? (
            <div className="py-2">
                            {previewResults.map((product) => {
                // Add this debug log
                console.log('Price debug:', {
                  directPrice: product.prices?.price,
                  articlePrice: product.articles?.[0]?.prices?.price,
                  fullPricesObject: product.prices,
                  fullArticlesObject: product.articles
                });
                const article = {
                  pharmacode: product._id,
                  description: {
                    description: product.description?.description || 'No description available',
                    longDescription: product.description?.longDescription || product.longDescription || 'No description available'
                  },
                  hasPhoto: product.hasPhoto || false,
                  images: {
                    M: Array.isArray(product.images) ? product.images.map(img => img.replace(/^uploads\\/, '')) : []
                  },
                  // Updated price access logic
                  prices: product.prices?.price || 
                         (product.articles?.[0]?.prices?.price) || 
                         null
                };
                console.log('Processed article:', article);

                return (
                  <div
                    key={product._id}
                    onClick={() => handlePreviewClick(product._id)}
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center space-x-3"
                  >
                    <div className="w-12 h-12 flex-shrink-0">
                      {article.hasPhoto && article.images.M.length > 0 ? (
                        <img
                          src={`https://pharma-outlet.com/uploads/${getOptimizedImageUrl(article.images.M).replace(/\\/g, '/')}`}
                          alt={article.description.description}
                          className="w-full h-full object-contain"
                        />
                      ) : (
                        <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                          <span className="text-xs text-gray-500">No image</span>
                        </div>
                      )}
                    </div>
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium text-gray-900 truncate">
                        {article.description.longDescription}
                      </p>
                      <p className="text-sm text-gray-500">
                        {article.prices ? `${article.prices} CHF` : 'Pris nid verfüegbar'}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="p-4 text-center text-gray-500">No results found</div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchBar;