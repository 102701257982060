export default function Article1() {
    return (
      <div className="overflow-hidden bg-gray-50 py-12 sm:py-24">
        <div className="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
          <div className="max-w-4xl">
            <p className="text-base/7 font-semibold text-[#4d7f6e]">Dreirosen Apotheke</p>
            <h1 className="mt-2 text-pretty text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
            ENIGMA SCHLAF
            </h1>
            <p className="mt-6 text-balance text-sm text-gray-700">
            Diese Unterlagen richten sich hauptsächlich nach den Forschungsresultaten der Professoren Dr. Jürgen Zulley und Karl Hecht. Karl Hecht war es, der sich als
            einer der ersten Wissenschaftler um die Forschung über den Schlaf kümmerte und das erste Schlaflabor in Berlin Mitte der 1960-er Jahre errichtete. Die
            Ausführungen und Angaben wie mit naturheilkundlichen Methoden Schlafstörungen zu begegnen ist, stützen sich auf allgemeine Erfahrungen aus der
            traditionellen europäischen Medizin und Praxis-Erfahrungen des Schreibers Markus Lachat, Drogist HF
            </p>
          </div>
          <section className="mt-10 grid grid-cols-1 lg:grid-cols-2 lg:gap-x-8 lg:gap-y-16">
            <div className="lg:pr-8">
              <h2 className="text-pretty text-2xl font-semibold tracking-tight text-gray-900">Um Schlaf zu verstehen, müssen vorab drei Prinzipien akzeptiert werden:</h2>
              <p className="mt-6 text-base/7 text-gray-600">
              1. Unser Organismus ist eine Naturkonstruktion, die in Millionen Jahren optimiert wurde. Die Konstellation, die diese
                Entwicklung ermöglichte und bis heute aufrecht erhält, war immer die gleiche:
              </p>
              <ul className="mt-4 space-y-2 text-base/7 text-gray-600 list-disc list-inside">
                    <li>Wechsel von Licht und Dunkelheit</li>
                    <li>Wechsel von Bewegung und Ruhe</li>
                    <li>Wechsel von Nahrungsaufnahme und Hunger</li>
                </ul>
              <p className="mt-8 text-base/7 text-gray-600">
              2. Wir müssen den Organismus als System ansehen. Bei der Systembetrachtung muss jede Zelle als Baustein für
                Gewebe, Organe, Regelkreise und schliesslich für ganze Funktionsgebilde den Hauptfokus bilden. Funktioniert jede
                einzelne Zelle gut, dann gibt es für den Menschen kein Gesundheitsproblem. Im Mittelpunkt steht die
                Energieversorgung der Zelle. Die Energie der Zelle muss unter allen Umständen gewährleistet sein, da sie zwischen
                Gesundheit, Krankheit und Heilung entscheidet. Ein gesunder Schlaf ist allerdings für die Zellenergie grundlegend,
                denn während des Schlafes haben wir die höchste Rate der Zellneogenese (Zellneubildung). Ein gesundes System, sei
                es eine Zelle, ein Organ, eine Familie, ein Ökosystem, ein Arbeitsteam sowie auch unser Organismus, regelt sich
                selbsttätig immer wieder in einen optimalen Zustand ein (wobei der Schlaf ein Hauptteil dieses „Einpendelns“ auf
                Normwerte einnimmt). Letztlich dient die Selbstorganisation und Selbstregulation aber immer nur einem einzigen
                Ziel: Sicherung der Energieversorgung.
              </p>
              <p className="mt-8 text-base/7 text-gray-600">
              3. Der Einzelne muss lernen, dass sein Verhalten zum überwiegenden Teil das unter Punkt 2 genannte System steuert
              </p>
            </div>
            <div className="pt-5 lg:row-span-2 lg:-mr-16 xl:mr-auto">
              <div className="-mx-8 grid grid-cols-2 gap-4 sm:-mx-16 sm:grid-cols-4 lg:mx-0 lg:grid-cols-2 lg:gap-4 xl:gap-8">
                <div className="aspect-square overflow-hidden rounded-xl shadow-lg hover:shadow-2xl transition-shadow duration-300">
                  <img
                    alt=""
                    src="images/sleepimg1.jpg"
                    className="block w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
                  />
                </div>
                <div className="aspect-square overflow-hidden rounded-xl shadow-lg hover:shadow-2xl transition-shadow duration-300 lg:-mt-40">
                  <img
                    alt=""
                    src="images/sleepimg2.jpg"
                    className="block w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
                  />
                </div>
                <div className="aspect-square overflow-hidden rounded-xl shadow-lg hover:shadow-2xl transition-shadow duration-300">
                  <img
                    alt=""
                    src="images/sleepimg3.jpg"
                    className="block w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
                  />
                </div>
                <div className="aspect-square overflow-hidden rounded-xl shadow-lg hover:shadow-2xl transition-shadow duration-300 lg:-mt-40">
                  <img
                    alt=""
                    src="images/sleepimg4.jpg"
                    className="block w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
                  />
                </div>
              </div>
            </div>
          </section>
          <div className="mt-0 bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-pretty text-2xl font-semibold tracking-tight text-gray-900">
            Alle aktiven Komponenten werden in Zyklen von passiven abgelöst.
            </h2>
            <p className="mt-4 text-base/7 text-gray-600">
             Licht wechselt mit Dunkelheit, Strahlung mit
            Abschirmung, Bewegung mit Ruhe und Nahrungsaufnahme mit Fastenzeiten. Diese über Jahrmillionen konstanten Wechsel
            sind ohne Zweifel in unser System einprogrammiert worden und das System funktioniert – wie später dargestellt wird – auch
            heute noch solange gut, wie wir diese Zyklen einwirken lassen. Wird der Dreiklang dagegen gestört, ist die Harmonie der
            Körperfunktionen dahin. Denn eins dürfen wir nicht ausser Acht lassen: Wir haben noch heute das Körpersystem, wie es vor
            vielen hunderttausend Jahren an die Gegebenheiten angepasst wurde. Wir begegnen dem High-Tech-Zeitalter mit der
            Körperkonstruktion des Urmenschen.
            </p>
          </div>
          <div className="mt-10 bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-pretty text-2xl font-semibold tracking-tight text-gray-900">
            Was ist Schlaf?
            </h2>
            <p className="mt-4 text-base/7 text-gray-600">
            Auf ein ganzes Leben hochgerechnet, summieren sich die „normalen“ Schlafstunden auf 25 Jahre. Somit hat Schlaf einen ganz
            erheblichen Anteil an unserem Leben und doch ist er in der Allgemeinheit weitgehend unbekannt. Das erstaunt umso mehr,
            denn während wir schlafen, geschehen in unserem Körper unglaubliche Prozesse und Veränderungen:
            </p>
            <ul className="mt-4 space-y-2 text-base/7 text-gray-600 list-disc list-inside">
                <li>Schlafen ist nicht ruhen, es ist eine Art Wachen. Nach acht Stunden Schlaf haben wir nur etwa 50 Kilokalorien
                weniger Energie verbraucht als im Wachzustand.</li>
                <li>Der Schlaf ist kein einheitlicher Zustand, sondern ein Wechsel zwischen verschiedenen Phasen mit mehr oder
                weniger Ruhe und Aktivität. Er gleicht eher einer Berg- und Talfahrt als einem gleichmässigen Ruhezustand. Die
                Schlafmedizin unterteilt den Schlaf in fünf Phasen: Die Schlafstadien eins und zwei (leichter Schlaf), das Stadium drei
                (leichter Tiefschlaf) und das Stadium vier (den Tiefschlaf). Das fünfte Stadium ist der Traumschlaf (REM-Schlaf).
                </li>
                <li>Eine typische Nacht, die gegen 23 Uhr beginnt hat ca. zwei Tiefschlafphasen in der ersten Nachthälfte. Unterbrochen
                wird der Tiefschlaf durch vier bis fünf Traumschlafphasen, die alle 90 Min. wiederkehren und im Laufe der Nacht
                immer länger werden. Gegen Morgen liegt zwischen diesen Traumphasen nur noch leichter Schlaf.</li>
            </ul>
          </div>
          <div className="mt-10 bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-pretty text-2xl font-semibold tracking-tight text-gray-900">
            Die innere Uhr bestimmt unsere Gesundheitsfunktionen
            </h2>
            <p className="mt-4 text-base/7 text-gray-600">
            Die Rhythmik aller Prozesse des menschlichen Organismus ist genetisch verankert, kommt also von innen heraus und ist
            wirksam konditioniert von Zeitgebern in der Umwelt. Für derartige Zeitgeber müssen Sensoren vorhanden sein, die
            wiederum genetisch determiniert sind. Die wichtigsten äusseren Zeitgeber sind:
            </p>
            <ul className="mt-4 space-y-2 text-base/7 text-gray-600 list-disc list-inside">
                <li>Licht-Dunkel-Wechsel</li>
                <li>Temperatur</li>
                <li>Elektrische und magnetische Grössen der Erde und Atmosphäre</li>
                <li>Umgebungsfeuchtigkeit</li>
            </ul>
          </div>
          <div className="mt-10 bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-pretty text-2xl font-semibold tracking-tight text-gray-900">
            Vor allem der natürliche Rhythmus von hell/dunkel ist der dominierende Faktor
            </h2>
            <p className="mt-4 text-base/7 text-gray-600">
            Die modernen technischen Kommunikationsmittel (Internet, Handy) durch die jeder zu jeder Zeit an jedem Ort erreichbar ist,
            sind zudem immer ernstere Störfaktoren für unser rhythmisches Funktionsgeschehen, welches nicht zu Unrecht als innere
            Uhr bezeichnet wird (siehe Reflex-Studie, Petrov-Studie, Prof. Adlkofer 1997, 2005).
            </p>
            <h2 className="mt-10 text-pretty text-2xl font-semibold tracking-tight text-gray-900">
            Wo und was ist die Innere Uhr?
            </h2>
            <p className="mt-4 text-base/7 text-gray-600">
            Früher nahm man an, der Sitz der inneren Uhr sei die Zirbeldrüse, später entdeckte man, dass der kleine Kreuzpunkt der
            beiden Sehnerven einen kleinen Nervenknoten besitzt: Nukleus suprachiasmaticus und glaubte aus einer Reihe von
            Versuchen nun die innere Uhr gefunden zu haben. Heute ist jedoch klar: Es braucht gar kein Organ, um einen Rhythmus im
            Körper zu erzeugen, da dies jede einzelne Körperzelle hervorragend kann. Jede Zelle in unserem Organismus besitzt die
            Fähigkeit, Tagesrhythmen zu erzeugen. So ist sich die Forschung heute einig, dass wir Milliarden von inneren Uhren in uns
            tragen.
            </p>
          </div>
          <div className="mt-10 bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-pretty text-3xl font-semibold tracking-tight text-gray-900">
            VORTEILE EINER FUNKTIONIERENDEN INNEREN UHR 
            </h2>
            <h2 className="mt-10 text-pretty text-2xl font-semibold tracking-tight text-gray-900">
            Schlafen sortiert aus
            </h2>
            <p className="mt-4 text-base/7 text-gray-600">
            Es ist «Unfug», sagt der Wissenschaftsjournalist Peter Spork (siehe sein Buch „Das Schlafbuch“, ISBN 978 3 499 62121 5), wenn manche
            Menschen behaupten, dass alles, was wir je erlebt haben, irgendwo im Gehirn seinen Platz hat. Was einmal im Schlaf
            aussortiert wurde, bleibt auf ewig vergessen. Und das ist gut so, denn die Sturzflut an Informationen, die das Gehirn im
            Wachen überschwemmt, würde es in kurzer Zeit ertränken. Auch im Alter kommen deshalb nur Erinnerungen hoch, die
            früher einmal wichtig waren und fest abgespeichert worden sind.
            </p>
            <h2 className="mt-10 text-pretty text-2xl font-semibold tracking-tight text-gray-900">
            Schlafmangel macht dumm
            </h2>
            <p className="mt-4 text-base/7 text-gray-600">
           Schlafmangel macht, schreibt Peter Spork, nicht nur krank, sondern auch dumm. Denn eine der Hauptaufgaben des Schlafes
            scheint darin zu bestehen, am Tag Gelerntes dauerhaft zu speichern. Die Forscher interessierten sich dabei vor allem für den
            Tiefschlaf.  Hier geschieht nämlich Seltsames: Grosse, gleichmässige Erregungswellen ziehen über das Gehirn. Diesen Effekt
            konnte Hirnforscher Jan Born von der Universität Lübeck direkt im Experiment simulieren: Er setzte Versuch-schläfern
            Elektrodenkappen auf, die nicht nur Signale empfangen, sondern auch abgeben konnten. Mit diesen Kappen induzierte Born
            in den Gehirnen der Probanden die für den Tiefschlaf typischen Wellen. Er rief also eine Art Supertiefschlaf hervor. Das
            Ergebnis: Am folgenden Tag konnten sich die Versuchspersonen an Gelerntes besser erinnern als Vergleichspersonen ohne
            Superschlaf. Womit auch die Antwort auf die Funktion des Tiefschlafes gegeben wäre: Er dient dem Gedächtnis.
            </p>
            <h2 className="mt-10 text-pretty text-2xl font-semibold tracking-tight text-gray-900">
            Schlechter Schlaf greift Schönheit, Körper und Psyche an
            </h2>
            <p className="mt-4 text-base/7 text-gray-600">
            Der Schlaf ist für die Regeneration des Menschen unumgänglich notwendig. Schlaf, der zu kurz oder zu oberflächlich ist oder
            der zu häufig von Wachphasen unterbrochen wird, hat Unruhe, Erschöpfung, Verkrampfung, kurze Blackouts und
            Depressionen zur Folge. Diese Störungen verstärken wiederum Einschlaf- und Durchschlafkomplikationen.
            </p>
            <h2 className="mt-10 text-pretty text-2xl font-semibold tracking-tight text-gray-900">
            Körper-Rhythmusstörungen beschleunigen das Altern
            </h2>
            <p className="mt-4 text-base/7 text-gray-600">
            Wissenschaftliche Tests haben gezeigt, dass die schlechten Schläfer mit dem 24 Stundenrhythmus nicht synchron leben.
            Diese Menschen tun häufig ihre Arbeit während des Wachseins mit einem schlafenden Körper. Psychologische Tests ergaben
            zudem: Schlechte Schläfer neigen stark zu neurotischen und somatischen Beschwerden.
            </p>
          </div>
          <div className="mt-10 bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-pretty text-2xl font-semibold tracking-tight text-gray-900">
            ZUSAMMENFASSUNG: ERHOLSAM SCHLAFEN – VERGNÜGT ERWACHEN
            </h2>
            <ul className="mt-4 space-y-2 text-base/7 text-gray-600 list-disc list-inside">
                <li>LICHT / DUNKELHEIT</li>
                <li>Bewegung / Ruhe</li>
                <li>Rhythmus finden</li>
                <li> Verdauungsorgane gesund erhalten bzw. gesund lassen</li>
                <li>Schlafplatz und Materialien überdenken und Top-Qualität wählen</li>
                <li>Elektrofelder verringern</li>
                <li>Ernährung und Lebensmittel anpassen {'=>'} dichte Nahrungsmittel</li>
                <li>Vorübergehend als Unterstützung: Pflanzen, Schüsslersalze, Gemmotherapie etc.</li>
            </ul>
          </div>


          <div className="mt-10 bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-pretty text-2xl font-semibold tracking-tight text-gray-900">
                Einige bewährte Empfehlungen bei Schlafproblemen
            </h2>
            <div className="mt-4">
                <h3 className="text-lg font-semibold text-gray-900">Pflanzen</h3>
                <p className="mt-2 text-base/7 text-gray-600">
                <strong>Melisse:</strong> Dieser schon von Hippokrates erwähnte Lippenblütler ist eine der ältesten und bewährtesten Heilpflanzen bei Schlafstörungen. Die Melisse wirkt in erster Linie beruhigend und doch nervenstärkend. Ihre Inhaltsstoffe sind sehr ausgleichend auf Herz und Hirn.
                </p>
                <p className="mt-2 text-base/7 text-gray-600">
                <strong>Hopfen:</strong> Ist der bekannte Klassiker bei Ein-Schlafstörungen, Unruhe und leichten Angstzuständen. Die Wirkung ist aufgrund der pharmakowirksamen Substanzen plausibel.
                </p>
                <p className="mt-2 text-base/7 text-gray-600">
                <strong>Hafer:</strong> Neben einer Vielzahl von Wirkungen (probiotisch, bei Herzbeschwerden, Blähungen etc.) ist der Hafer ein ausgezeichnetes Mittel bei Schlafstörungen. Als Tee in langsamen Schlucken genossen, fördert er gleichzeitig Erholung und Durchblutung. Wir empfehlen Hafer in Form einer Urtinktur (Bsp. von Ceres).
                </p>
                <p className="mt-2 text-base/7 text-gray-600">
                <strong>Passionsblume:</strong> Das Kraut des ausdauernden Strauches beinhaltet Wirksubstanzen, die beim Einschlafen sehr helfen können, da sie leicht sedative und spasmolytische Effekte haben.
                </p>
                <p className="mt-2 text-base/7 text-gray-600">
                <strong>Allgemeines zum Aufbrühen von Tee:</strong> Je verdünnter der jeweilige Tee eingenommen wird, desto besser scheint oft die Wirkung. Also: Den Tee immer so zubereiten, dass er kaum riecht und ganz wenig Farbe trägt.
                </p>
            </div>
            <div className="mt-6">
                <h3 className="text-lg font-semibold text-gray-900">Schüsslersalze</h3>
                <p className="mt-2 text-base/7 text-gray-600">
                <strong>Nr. 7 Magnesium phosphoricum:</strong> Einschlafsalz – „Heisse 7“, Bewährtes Durchschlafssalz: ca. 10 Tabl. in ca. 2 dl. gekochtem Wasser auflösen und in kleinen Schlucken trinken. Zusätzlich Nr. 21 Zincum chloratum, wenn morgens unausgeschlafen.
                </p>
                <p className="mt-2 text-base/7 text-gray-600">
                <strong>Nr. 2 Calcium phosphoricum:</strong> Am besten hilft Calcium ebenfalls in der „heissen Form“ (siehe oben). Die Nr. 2 bewährt sich dann, wenn jemand gut einschläft und ca. 2 Uhr nachts aufwacht.
                </p>
                <p className="mt-2 text-base/7 text-gray-600">
                <strong>Nr. 5 Kalium phosphoricum:</strong> Bei grosser Erschöpfung und ausgedehnter Müdigkeit – „zu müde um zu schlafen“.
                </p>
                <p className="mt-2 text-base/7 text-gray-600">
                Wenn diese 3 Phosphorsalze zu wenig Entspannung verschaffen, kann das „Extrem-Mittel“ Nr. 14 Kalium bromatum D6 helfen. Davon nehme man jedoch max. 2 – 3 Tabl. und während einer begrenzten Einnahmedauer (10 – 14 Tage).
                </p>
                <p className="mt-2 text-base/7 text-gray-600">
                <strong>Nr. 8 Natrium chloratum:</strong> Oberflächlicher, zerhackter Schlaf, vormittags Müdigkeit: wirkt regulierend und steuert den Liquor.
                </p>
                <p className="mt-2 text-base/7 text-gray-600">
                <strong>Nr. 25 Aurum muraticum:</strong> Keinen Schlaf finden können (insbesondere auch indiziert bei alten Menschen).
                </p>
                <p className="mt-2 text-base/7 text-gray-600">
                <strong>Ein besonderer Trick:</strong> Hat man keinen entsprechenden Tee bereit, kein Schüsslersalz zu Hause oder im Hotel: Ein paar Körnchen Kochsalz lassen sich überall auftreiben! Diese auf die Zunge legen, zergehen lassen… und der Schlaf nimmt überhand. Der Effekt kommt über die elektrolytische Spannung zwischen Natrium und Chlor zustande. Diese Spannung senkt die Spannung der Gehirnflüssigkeit (dem sog. Liquor), welche oft für Schlaflosigkeit mitverantwortlich ist.
                </p>
            </div>
            <div className="mt-6">
                <h3 className="text-lg font-semibold text-gray-900">Bäder & Schlafzimmer-Duft</h3>
                <p className="mt-2 text-base/7 text-gray-600">
                Natürlich helfen auch beruhigende Fuss- und Vollbäder mit beruhigenden Düften wie zum Beispiel: Lavendel, Mandarine, Neroli und/oder Sandelholz.
                </p>
            </div>
            <div className="mt-6">
                <h3 className="text-lg font-semibold text-gray-900">Zu guter Letzt - Musik</h3>
                <p className="mt-2 text-base/7 text-gray-600">
                Musik ist für viele ein vortreffliches Schlafmittel: ruhige Melodien mit sechzig Taktschlägen pro Minute beeinflussen, so zeigen wissenschaftliche Studien, Herz, Nerven und Hormone.
                </p>
            </div>
            </div>

        </div>
      </div>
    )
  }
  