import Nav from "../components/Nav"; 
import Footer from '../components/Footer';
import Blogs from "../components/Blog/Blogs";
import Banner from "../components/Banner";

function BlogPage() {
    return (
      <div>
      <Banner />
       <Nav />
       <Blogs />
       <Footer />
      </div>
    );
  }
  
  export default BlogPage;