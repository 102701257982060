import React, { useState, useEffect } from 'react';
import { getProfile } from '../../services/apiService';

export default function Example() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        setIsLoggedIn(true);
        try {
          const response = await getProfile();
          setUser(response.data.user); // Change this line to access the nested user object
        } catch (error) {
          console.error('Error fetching user profile:', error);
        }
      }
    };
    
    checkAuth();
  }, []);

  const ChevronRightSVG = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-5 h-5 text-gray-400"
      fill="currentColor"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path d="M10 6l6 6-6 6-1.41-1.42L13.17 12l-4.58-4.58L10 6z" />
    </svg>
  );

  return (
    <div className="bg-white">
      <div className="min-h-screen relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20">
        <div className="mx-auto max-w-7xl pb-24 pt-3 sm:pb-32 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:py-40">
          {/* Text Section */}
          <div className="px-6 lg:px-0 lg:pt-4">
            <div className="mx-auto max-w-2xl">
              <div className="max-w-lg">
                <div className="mt-12 sm:mt-20 lg:mt-16">
                  <div className="inline-flex space-x-6">
                    {!isLoggedIn ? (
                      <>
                        <a href="/loginpage" className="inline-flex items-center space-x-2">
                          <span className="rounded-full bg-indigo-300/10 px-3 py-1 text-sm text-gray-900 ring-1 ring-inset ring-indigo-600/10">
                            Anmelden
                          </span>
                        </a>
                        <a
                          href="/registerpage"
                          className="inline-flex items-center space-x-2 text-sm font-medium text-gray-600"
                        >
                          <span>Registrieren</span>
                          <ChevronRightSVG />
                        </a>
                      </>
                    ) : (
                      <div className="inline-flex items-center space-x-2">
                        <span className="rounded-full bg-indigo-300/10 px-3 py-1 text-sm text-gray-900 ring-1 ring-inset ring-indigo-600/10">
                          {user && `Welcome, ${user.name} ${user.surname}`}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <h1
                  id="lcp-element"
                  className="mt-10 text-5xl font-light tracking-tight text-gray-900 sm:text-7xl"
                  style={{
                    fontFamily: 'Arial, sans-serif',
                  }}
                >
                  Mit uns blibts Glaibasel gsund!
                </h1>
                <p className="mt-6 text-lg text-gray-600">
                  Entdecken Sie fachkundige Betreuung, hochwertige Medikamente und persönliche
                  Beratung für Ihre Gesundheit und Ihr Wohlbefinden.
                </p>
                <div className="mt-10 flex items-center gap-x-6">
                  <a
                    href="/products"
                    className="rounded-full bg-[#E05257] px-3.5 py-2.5 text-sm font-semibold text-white shadow-lg focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                  >
                    Zum Shop
                  </a>
                  <a href="/vacinationspage" className="text-sm font-semibold text-gray-900">
                    Labor <span aria-hidden="true">→</span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Video Section */}
          <div className="mt-20 sm:mt-24 md:mx-auto md:max-w-2xl lg:mx-0 lg:mt-0 lg:w-screen">
            <div
              className="absolute inset-y-0 right-1/2 -z-10 -mr-10 w-[200%] skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 md:-mr-20 lg:-mr-36"
              aria-hidden="true"
            />
            <div className="shadow-lg md:rounded-3xl">
              <div className="bg-[#E05257] md:[clip-path:inset(0_round_theme(borderRadius.3xl))]">
                <div
                  className="absolute -inset-y-px left-1/2 -z-10 ml-10 w-[200%] skew-x-[-30deg] bg-[#F2F7F3] opacity-20 ring-1 ring-inset ring-white md:ml-20 lg:ml-36"
                  aria-hidden="true"
                />
                <div className="relative px-6 pt-8 sm:pt-16 md:pl-16 md:pr-0">
                  <div className="mx-auto max-w-2xl md:mx-0 md:max-w-none">
                    <div className="w-screen overflow-hidden rounded-tl-xl">
                      <video
                        src="videos/HeroVideo.mp4"
                        autoPlay
                        loop
                        muted
                        playsInline
                        preload="none"
                        poster="images/HeroVideoPoster.jpg"
                        aria-label="Video showcasing Dreirosen services"
                        className="object-cover w-full h-full"
                      />
                    </div>
                  </div>
                  <div
                    className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 md:rounded-3xl"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Bottom Gradient */}
        <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
      </div>
    </div>
  );
}
