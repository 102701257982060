import React, { useState, useEffect } from 'react';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import Banner from '../components/Banner';
import BestSellers from '../components/Home/BestSellers';
import BlogSection from '../components/Home/BlogSection';
import PhoneSection from '../components/Home/PhoneSection';
import HelpContent from '../components/Home/HeroDesktop';
import ServicesMobile from '../components/Home/ServicesMobile';
import HeroMobile from '../components/Home/HeroMobile';
import LabServices from '../components/Home/LabServices';
import InfoModal from '../components/Home/InfoModal';
import Categories from '../components/Home/Categories';
import LabServicesMobile from '../components/Home/LabServicesMobile';
import ServicesDesktop from '../components/Home/ServicesDesktop';

function Home() {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1030);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 1030);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div>
      <div className="hidden md:block">
        <Banner />
        <InfoModal />
        <Nav />
        {!isMobileView && <Categories />}
        {isMobileView ? <HeroMobile /> : <HelpContent />}
        <ServicesDesktop />
        <BestSellers /> 
        <LabServices />
        <BlogSection />
        <PhoneSection />
        <Footer />
      </div>

      <div className="block md:hidden">
        <InfoModal />
        <Nav />
        <HeroMobile />
        <BestSellers /> 
        <ServicesMobile />
        <LabServicesMobile />
        <BlogSection />
        <Footer />
      </div>  
    </div>
  );
}

export default Home;
