export default function Example() {
  const CheckCircleSVG = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5 flex-none text-[#4D7F6E]"
      fill="currentColor"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm-1 15L7 13l1.4-1.4 2.6 2.6 5.6-5.6L18 10z" />
    </svg>
  );

  const PhoneSVG = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5 flex-none text-[#4D7F6E]"
      fill="currentColor"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path d="M6.62 10.79a15.564 15.564 0 006.59 6.59l2.2-2.2a1.002 1.002 0 011.11-.27 11.493 11.493 0 003.59.61c.55 0 1 .45 1 1V20a1 1 0 01-1 1c-9.39 0-17-7.61-17-17a1 1 0 011-1h3.5c.55 0 1 .45 1 1 0 1.25.21 2.47.61 3.59.14.34.05.74-.27 1.11l-2.2 2.2z" />
    </svg>
  );

  const EnvelopeSVG = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5 flex-none text-[#4D7F6E]"
      fill="currentColor"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path d="M2 5a2 2 0 012-2h16a2 2 0 012 2v14a2 2 0 01-2 2H4a2 2 0 01-2-2zm18-1H4v.01L12 11l8-6.99zm0 2l-8 6.01-8-6v12h16z" />
    </svg>
  );

  const MapPinSVG = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5 flex-none text-[#4D7F6E]"
      fill="currentColor"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 5.2 11.25 6.6 12.88a1.25 1.25 0 001.8 0C13.8 20.25 19 14.25 19 9c0-3.87-3.13-7-7-7zm0 9.25a2.25 2.25 0 110-4.5 2.25 2.25 0 010 4.5z" />
    </svg>
  );

  return (
    <div className="bg-gradient-to-b from-white to-gray-50 px-6 py-16 lg:px-8">
      <div className="mx-auto max-w-6xl text-base leading-7 text-gray-700">
        <header className="mb-10 text-center">
          <p className="text-lg font-semibold text-[#4D7F6E]">Dreirosen Apotheke</p>
          <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
            Offene Stellen
          </h1>
        </header>
        <p className="mb-10 text-lg leading-8 text-center">
          Die Dreirosen Apotheke ist eine junge, unabhängige Apotheke an bevorzugter Lage im Kleinbasler Matthäus-Quartier. Mit modernster Einrichtung und einem breiten Sortiment an Gesundheits-, Schönheits- und Sachpflege-Produkten widmen wir uns der täglichen Versorgung und Gesunderhaltung unserer Quartierbevölkerung.
        </p>

        <div className="grid grid-cols-1 gap-y-10 lg:grid-cols-2 lg:gap-x-12">
          {/* Apotheker/in Section */}
          <section className="bg-white border border-gray-200 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 p-8">
            <p className="text-2xl font-semibold text-gray-900">Apotheker/in</p>
            <p className="mt-4 text-gray-700">
              Als Unterstützung für unser Team suchen wir per sofort oder nach Vereinbarung eine/n Apotheker/in ca. 60%.
            </p>
            <p className="mt-4 font-semibold text-gray-800">Tätigkeitsgebiet:</p>
            <ul className="mt-4 space-y-4">
              {[
                'Kontrolle der bestellten Medikamente',
                'Verantwortlich für Rezeptur und Defektur nach GMP',
                'Allgemeine Kundenberatung',
                'Mitverantwortlich für den Betäubungsmittel-Verkehr',
                'Trägt die Verantwortung für pharmazeutische Triage, Rückfragen, Intervention, Dokumentation, Überwachung aller pharmazeutischen Dossiers und Datenschutz',
              ].map((task, index) => (
                <li key={index} className="flex items-start gap-x-3">
                  <CheckCircleSVG />
                  <span>{task}</span>
                </li>
              ))}
            </ul>

            <p className="mt-6 font-semibold text-gray-800">Anforderungen:</p>
            <ul className="mt-4 space-y-4">
              {[
                'Studienabschluss in Pharmazie',
                'Berufserfahrung in Schweizer Apotheken',
                'Berufsausübungsbewilligung (BAB) und OKP Zulassung',
                'Sehr gute Sozial- und Fachkompetenz',
                'Organisationstalent',
                'Kooperative und lösungsorientierte Führungspersönlichkeit',
                'Eigeninitiative und hohes Engagement',
                'Teamfähigkeit',
                'Motivation, Teamgeist, Offenheit für Veränderung und Flexibilität',
              ].map((requirement, index) => (
                <li key={index} className="flex items-start gap-x-3">
                  <CheckCircleSVG />
                  <span>{requirement}</span>
                </li>
              ))}
            </ul>
          </section>

          {/* Pharma-Assistent/in EFZ Section */}
          <section className="bg-white border border-gray-200 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 p-8">
            <p className="text-2xl font-semibold text-gray-900">Pharma-Assistent/in EFZ</p>
            <p className="mt-4 text-gray-700">
              Als Unterstützung für unser Team suchen wir nach Vereinbarung eine/n Pharma-Assistent/in EFZ 60-100%.
            </p>
            <p className="mt-4 font-semibold text-gray-800">Tätigkeitsgebiet:</p>
            <ul className="mt-4 space-y-4">
              {[
                'Allgemeine Kundenberatung',
                'Pflege der Warenbewirtschaftung',
                'Verfalldatenkontrolle',
                'Betreuung der Aussendienstmitarbeitenden und deren zugehörigen Unternehmen',
                '(Mit-)Organisation von Weiterbildungssequenzen',
                'Krankenkassenwesen',
              ].map((task, index) => (
                <li key={index} className="flex items-start gap-x-3">
                  <CheckCircleSVG />
                  <span>{task}</span>
                </li>
              ))}
            </ul>

            <p className="mt-6 font-semibold text-gray-800">Anforderungen:</p>
            <ul className="mt-4 space-y-4">
              {[
                'Abgeschlossene Ausbildung als Pharma-Assistent/in EFZ',
                'Berufserfahrung in Schweizer Apotheken',
                'Abgeschlossene Berufsbildnerkurs von Vorteil',
                'Sehr gute Sozial- und Fachkompetenz',
                'Teamfähigkeit',
                'Eigeninitiative und hohes Engagement',
                'Motivation, Offenheit für Veränderung und Flexibilität',
              ].map((requirement, index) => (
                <li key={index} className="flex items-start gap-x-3">
                  <CheckCircleSVG />
                  <span>{requirement}</span>
                </li>
              ))}
            </ul>
          </section>
        </div>

        {/* Additional Information Section */}
        <div className="mt-12 bg-white border border-gray-200 rounded-lg shadow-lg p-8 space-y-8">
          <h2 className="text-2xl font-semibold text-gray-900">Wir bieten</h2>
          <p className="text-gray-700 flex items-start gap-x-3">
            <CheckCircleSVG />
            Moderne Arbeitsbedingungen, Unterstützung bei Weiterbildung sowie persönliche und berufliche Weiterentwicklung.
          </p>
          <p className="text-gray-700 flex items-start gap-x-3">
            <CheckCircleSVG />
            Ab Januar 2023 erhalten alle Mitarbeitenden sechs Wochen Ferien pro Jahr.
          </p>
          <p className="text-gray-700 flex items-start gap-x-3">
            <PhoneSVG />
            Für Auskünfte steht Ihnen Frau Ann-Christine Weber (061 691 74 88) zur Verfügung.
          </p>

          <div className="border-t border-gray-300 pt-6">
            <h3 className="text-lg font-semibold text-gray-800 mb-2">Bewerbung</h3>
            <p className="text-gray-700">
              Ihre Bewerbungsunterlagen senden Sie bitte an:
            </p>
            <div className="mt-4 space-y-1 text-gray-700">
              <p><strong>Dreirosen Apotheke</strong></p>
              <p>z.H. Frau Ann-Christine Weber</p>
              <p className="flex items-start gap-x-3">
                <EnvelopeSVG />
                <a href="mailto:info@dreirosenapotheke.ch" className="text-indigo-600 hover:underline">info@dreirosenapotheke.ch</a>
              </p>
              <p className="flex items-start gap-x-3">
                <MapPinSVG />
                Klybeckstrasse 140, 4057 Basel
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
