export default function VaccinationServices() {
  const CalendarSVG = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6 text-white"
      fill="currentColor"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path d="M8 2a1 1 0 0 0-2 0v1H6A4 4 0 0 0 2 7v12a4 4 0 0 0 4 4h12a4 4 0 0 0 4-4V7a4 4 0 0 0-4-4h-.001V2a1 1 0 1 0-2 0v1H8V2ZM6 6h12a2 2 0 0 1 2 2v3H4V8a2 2 0 0 1 2-2Zm14 7v6a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-6h16Zm-9 1H7a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2Zm6 0h-2a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2Z" />
    </svg>
  );

  return (
    <div className="bg-[#00A1BB] py-12 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="text-center mx-auto max-w-2xl">
          <h2 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl">
            Unsere Impfleistungen
          </h2>
          <p className="mt-4 text-lg leading-8 text-white">
            Anmelden können Sie sich via Online-Formular, telefonisch oder direkt vor Ort.
          </p>
        </div>

        {/* Grippen-Impfung Section */}
        <div className="mx-auto mt-12 p-6 rounded-xl bg-white/10 backdrop-blur-lg shadow-lg ring-1 ring-inset ring-white/30 hover:bg-white/20 transition-transform transform hover:-translate-y-1">
          <h3 className="text-3xl font-bold text-white">Grippen-Impfung</h3>
          <p className="mt-3 text-base leading-7 text-white">
            <strong>Wichtig:</strong> Es können nur erwachsene Personen geimpft werden. Sollten Sie an einer Vorerkrankung oder einer Allergie leiden, bitten wir Sie dies bei der Anmeldung zu vermerken.
          </p>
          <p className="mt-4 text-white text-base leading-7">
            Die Grippeimpfung schützt vor der saisonalen Grippeerkrankung. Nicht nur Sie werden geschützt, sondern auch Ihr direktes Umfeld.
          </p>
          <p className="mt-4 text-white text-base leading-7">
            Empfohlen wird die Impfung für bestimmte Risikogruppen (zum Beispiel Immunsupprimierte, Diabetiker, Asthmatiker, Pflegefachkräfte und medizinisches Personal) und ab dem 65. Lebensjahr.
          </p>
          <p className="mt-4 text-white text-base leading-7">
            Der Preis für die Grippe-Impfung beträgt CHF 46.85 (Grippeimpfstoff + Dienstleistung) welcher direkt bei uns vor Ort bezahlt werden muss.
          </p>
          <p className="mt-4 text-white text-base leading-7">
            Für Personen ab 65 Jahren gibt es einen hochdosierten Grippeimpfstoff. Der Preis für diese Grippe-Impfung beträgt CHF 68.80 (Grippeimpfstoff + Dienstleistung).
          </p>
          <div className="mt-6 flex items-center gap-3">
            <CalendarSVG />
            <p className="text-white">
              Gerne können Sie weiter unten im Kalender einen freien Termin aussuchen. Zögern Sie nicht, uns auch telefonisch zu kontaktieren.
            </p>
          </div>
        </div>

        {/* Zecken-Impfung Section */}
        <div className="mx-auto mt-12 p-6 rounded-xl bg-white/10 backdrop-blur-lg shadow-lg ring-1 ring-inset ring-white/30 hover:bg-white/20 transition-transform transform hover:-translate-y-1">
          <h3 className="text-3xl font-bold text-white">Zecken-Impfung</h3>
          <p className="mt-3 text-base leading-7 text-white">
            Ab Oktober 2022 führen wir in der Dreirosen Apotheke auch Zecken-Impfungen (FSME-Impfung) durch. Anmelden können Sie sich via Online-Formular, telefonisch oder direkt vor Ort.
          </p>
          <p className="mt-4 text-base leading-7 text-white">
            <strong>WICHTIG:</strong> Es können nur erwachsene Personen geimpft werden. Sollten Sie an einer Vorerkrankung oder einer Allergie leiden, so bitten wir Sie dies bei der Anmeldung zu vermerken.
          </p>
          <p className="mt-4 text-base leading-7 text-white">
            Die Zecken-Impfung schützt Sie vor einer Hirnhautentzündung. Damit Sie vollständig geschützt sind, sind drei Impfungen notwendig. Idealerweise starten Sie mit der Grundimmunisierung in der kalten Jahreszeit, da es ca. 6 bis 12 Monate braucht, bis Sie vollständig geimpft sind.
          </p>
          <p className="mt-4 text-base leading-7 text-white">
            Der Preis für die jeweilige Zecken-Impfung beträgt CHF 69.00.
          </p>
          <div className="mt-6 flex items-center gap-3">
            <CalendarSVG />
            <p className="text-white">
              Gerne können Sie weiter unten im Kalender einen freien Termin aussuchen. Zögern Sie nicht, uns auch telefonisch zu kontaktieren.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
