import Nav from "../components/Nav";
import Footer from "../components/Footer";
import OurServices from "../components/Labor/OurServices";
import VacDetails from "../components/Labor/VacDetails";
import ImportantInfo from "../components/Labor/ImportantInfo";
import LabHero from "../components/Labor/LabHero";
import Steps from "../components/Labor/Steps";


function VacinationsPage() {
    return (
      <div>
        <Nav />
        <LabHero />
        <OurServices />
        <ImportantInfo />
        <Steps />
        <VacDetails />
        <Footer />
      </div>
    );
  }
  
  export default VacinationsPage;
