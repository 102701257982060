'use client';
import React, { useState, lazy, Suspense, useCallback, useMemo } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'; // Icons for navigation

// Lazy load heavy icons
const FaSyringe = lazy(() => import('react-icons/fa').then((mod) => ({ default: mod.FaSyringe })));
const FaShieldAlt = lazy(() => import('react-icons/fa').then((mod) => ({ default: mod.FaShieldAlt })));
const FaVial = lazy(() => import('react-icons/fa').then((mod) => ({ default: mod.FaVial })));
const FaVirus = lazy(() => import('react-icons/fa').then((mod) => ({ default: mod.FaVirus })));
const FaHeartbeat = lazy(() => import('react-icons/fa').then((mod) => ({ default: mod.FaHeartbeat })));
const FaUserShield = lazy(() => import('react-icons/fa').then((mod) => ({ default: mod.FaUserShield })));

export default function VaccinationSlider() {
  const vaccinationDetails = useMemo(
    () => [
      {
        vaccination: 'Zeckenimpfung',
        details: `Preis: CHF 47.35 (Impfstoff) zuzüglich CHF 20.00 (Impfservice)
Alter: Ab 16 Jahren
Die Zeckenimpfung wird insgesamt dreimal verabreicht. Es wird jedoch unterschieden zwischen dem normalen- und dem Schnellimpfschema.`,
        icon: <FaSyringe />,
      },
      {
        vaccination: 'Grippeimpfung',
        details: `Preis: CHF 13.70 (Impfstoff) zuzüglich CHF 20.00 (Impfservice)
Alter: Ab 16 Jahren
Die Impfung ist saisonal abhängig (Start meist im Oktober / November bis Februar).`,
        icon: <FaShieldAlt />,
      },
      {
        vaccination: 'Herpes Zoster',
        details: `Preis: CHF 174.65 (Impfstoff) zuzüglich CHF 20.00 (Impfservice)
Alter: Ab 18 Jahren, empfohlen wird die Impfung ab dem 65. Lebensjahr.`,
        icon: <FaVial />,
      },
      {
        vaccination: 'Diphtherie, Tetanus und Pertussis',
        details: `Preis: CHF 36.30 (Impfstoff) zuzüglich CHF 20.00 (Impfservice)
Alter: Ab 16 Jahren.`,
        icon: <FaVirus />,
      },
      {
        vaccination: 'Hepatitis A + B',
        details: `Hepatitis A Preis: CHF 53.00 (Impfstoff) zuzüglich CHF 20.00 (Impfservice).
Die Hepatitis A Impfung wird in zwei Dosen verabreicht.`,
        icon: <FaHeartbeat />,
      },
      {
        vaccination: 'Impfung gegen Mumpf, Masern und Röteln',
        details: `Preis: CHF 36.60 zuzüglich CHF 20.00 (Impfservice). Die Impfung wird in zwei Dosen verabreicht.`,
        icon: <FaUserShield />,
      },
    ],
    []
  );

  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? vaccinationDetails.length - 1 : prevIndex - 1
    );
  }, [vaccinationDetails.length]);

  const nextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex === vaccinationDetails.length - 1 ? 0 : prevIndex + 1
    );
  }, [vaccinationDetails.length]);

  return (
    <section
      className="relative py-10 sm:py-20 mt-20 mb-10 bg-gradient-to-r from-[#EDEFF2] via-[#DDE4E9] to-[#E3E8ED] bg-cover bg-center"
      style={{
        backgroundImage: "url('/images/resized_vacimage.webp')",
        backgroundBlendMode: 'overlay',
      }}
    >
      {/* Dark Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mb-8 sm:mb-12 text-center relative z-10">
          <h2 className="text-3xl sm:text-4xl font-extrabold text-white">
            Entdecken Sie Unsere Impfungen
          </h2>
          <p className="text-white mt-3 sm:mt-4 max-w-2xl sm:max-w-3xl mx-auto text-sm sm:text-lg">
            Wählen Sie aus unseren empfohlenen Impfungen, um Ihre Gesundheit zu schützen.
          </p>
        </div>

        <div className="relative overflow-hidden w-full max-w-4xl mx-auto">
          {/* Slider container */}
          <div
            className="flex transition-transform duration-500 ease-in-out"
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {vaccinationDetails.map((faq, index) => (
              <div
                key={index}
                className="min-w-full flex-shrink-0 flex justify-center items-center px-2 sm:px-6"
              >
                <div className="bg-white border border-gray-200 rounded-lg sm:rounded-3xl shadow-lg p-4 sm:p-8 text-left max-w-xs sm:max-w-lg mx-auto hover:shadow-2xl">
                  {/* Icon and title */}
                  <div className="flex items-center justify-center mb-4 sm:mb-6">
                    <Suspense fallback={<div>Loading...</div>}>
                      <div className="text-[#E43B3F] text-3xl sm:text-4xl">{faq.icon}</div>
                    </Suspense>
                  </div>
                  <h3 className="text-lg sm:text-2xl font-semibold text-gray-900 mb-4 text-center">
                    {faq.vaccination}
                  </h3>
                  <p className="text-gray-600 mb-4 sm:mb-6 leading-relaxed text-center text-sm sm:text-base">
                    {faq.details}
                  </p>
                  {/* Centered Button */}
                  <div className="flex justify-center">
                    <a
                      href="/vacinationspage"
                      className="bg-gradient-to-r from-[#FF8256] to-[#E43B3F] text-white px-6 sm:px-8 py-2 sm:py-3 rounded-full hover:from-[#E54B3F] hover:to-[#FF7043] transition duration-300 ease-in-out text-sm sm:text-base"
                    >
                      Mehr Erfahren
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Left and right navigation */}
          <button
            onClick={prevSlide}
            className="absolute left-2 sm:left-0 top-1/2 transform -translate-y-1/2 bg-white text-gray-800 p-2 sm:p-4 rounded-full shadow-lg hover:bg-gray-100 transition"
            aria-label="Previous slide"
          >
            <FiChevronLeft size={20} />
          </button>
          <button
            onClick={nextSlide}
            className="absolute right-2 sm:right-0 top-1/2 transform -translate-y-1/2 bg-white text-gray-800 p-2 sm:p-4 rounded-full shadow-lg hover:bg-gray-100 transition"
            aria-label="Next slide"
          >
            <FiChevronRight size={20} />
          </button>
        </div>
      </div>
    </section>
  );
}
