import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllCartItems, fetchProductDetails, updateCartQuantity, removeFromCart } from '../../services/apiService';


// Image dimensions configuration
const IMAGE_DIMENSIONS = {
  thumbnail: { width: 300, height: 300 },
  medium: { width: 600, height: 600 },
  large: { width: 900, height: 900 }
};


// Optimized image component with loading states
const OptimizedImage = ({ src, alt, className, isPriority = false }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);


  return (
    <div className="relative w-full h-full">
      {isLoading && (
        <div className="absolute inset-0 bg-gray-200 animate-pulse" />
      )}
      <picture>
        <img
          src={src}
          alt={alt}
          width={IMAGE_DIMENSIONS.medium.width}
          height={IMAGE_DIMENSIONS.medium.height}
          className={`${className} ${isLoading ? 'opacity-0' : 'opacity-100'} transition-opacity duration-300`}
          loading={isPriority ? "eager" : "lazy"}
          decoding="async"
          onLoad={() => setIsLoading(false)}
          onError={() => {
            setIsLoading(false);
            setError(true);
          }}
        />
      </picture>
      {error && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
          <span className="text-gray-500">Image not available</span>
        </div>
      )}
    </div>
  );
};


export default function Cart() {
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cartWithDetails, setCartWithDetails] = useState([]);
  const navigate = useNavigate();


  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/loginpage');
      return;
    }
    fetchCartItems();
  }, [navigate]);


  const handleQuantityChange = async (pharmacode, newQuantity) => {
    try {
      if (newQuantity < 1) return;
     
      setLoading(true);
      console.log('Updating quantity to:', newQuantity);
     
      const response = await updateCartQuantity(pharmacode, newQuantity);
     
      if (response.success) {
        await fetchCartItems();
      } else {
        console.error('Failed to update quantity');
      }
    } catch (error) {
      console.error('Error updating quantity:', error);
    } finally {
      setLoading(false);
    }
  };


  const handleRemoveFromCart = async (productId) => {
    try {
      setLoading(true);
      const response = await removeFromCart(productId);
     
      if (response.success) {
        await fetchCartItems();
      } else {
        console.error('Failed to remove item from cart');
      }
    } catch (error) {
      console.error('Error removing item from cart:', error);
    } finally {
      setLoading(false);
    }
  };
 


  // Fetch cart items and their product details
  const fetchCartItems = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await getAllCartItems();
      console.log('Cart items response:', response);
     
      if (response.success) {
        setCartItems(response.data.cart || []);
       
        // Fetch product details for each cart item
        const itemsWithDetails = await Promise.all(
          response.data.cart.map(async (item) => {
            try {
              const productDetails = await fetchProductDetails(item.pharmacode);
              console.log(`Product details for ${item.pharmacode}:`, productDetails);
              return {
                ...item,
                product: productDetails.data
              };
            } catch (error) {
              console.error(`Error fetching details for product ${item.pharmacode}:`, error);
              return item;
            }
          })
        );
       
        console.log('Items with details:', itemsWithDetails);
        setCartWithDetails(itemsWithDetails);
      } else {
        setError('Unable to fetch cart items');
      }
    } catch (error) {
      console.error('Error fetching cart items:', error);
      if (error.response?.status === 401) {
        navigate('/loginpage');
      } else {
        setError(error.message || 'Failed to load cart items');
      }
    } finally {
      setLoading(false);
    }
  };


  const calculateSubtotal = () => {
    return cartWithDetails.reduce((total, item) => {
      const price = parseFloat(item.price) || 0;
      const quantity = parseInt(item.quantity) || 0;
      const itemTotal = price * quantity;
      return total + itemTotal;
    }, 0);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="text-center">
          <svg className="animate-spin h-12 w-12 text-[#3A7861] mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
          </svg>
          <span className="mt-4 block text-lg text-gray-600">Loading your cart...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <div className="text-red-600 text-xl mb-4">⚠️</div>
          <p className="text-gray-800 text-lg">{error}</p>
          <button
            onClick={() => navigate('/products')}
            className="mt-6 inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-[#3A7861] hover:bg-[#2B5B48]"
          >
            Return to Shop
          </button>
        </div>
      </div>
    );
  }

  if (cartItems.length === 0) {
    return (
      <div className="min-h-[70vh] flex items-center justify-center">
        <div className="text-center px-4 py-8 bg-white max-w-md w-full">
          <svg 
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" 
            />
          </svg>
          <h2 className="mt-4 text-xl font-semibold text-gray-900">Your cart is empty</h2>
          <p className="mt-2 text-gray-500">Start shopping to add items to your cart.</p>
          <button
            onClick={() => navigate('/products')}
            className="mt-6 w-full inline-flex justify-center items-center px-4 py-3 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-[#3A7861] hover:bg-[#2B5B48] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#3A7861]"
          >
            Continue Shopping
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen">
      <main className="mx-auto max-w-2xl px-4 pb-24 pt-16 sm:px-6 lg:max-w-7xl lg:px-8">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-12">
          Shopping Cart
        </h1>

        <div className="lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-12 xl:gap-x-16">
          <section aria-labelledby="cart-heading" className="lg:col-span-7">
            <h2 id="cart-heading" className="sr-only">Items in your shopping cart</h2>

            <ul role="list" className="divide-y divide-gray-200 border-t border-b border-gray-200">
              {cartWithDetails.map((item) => {
                const productArticle = item.product?.product?.articles?.find(
                  article => article.pharmacode === item.pharmacode
                ) || {};

                const article = {
                  pharmacode: item.pharmacode,
                  description: {
                    description: productArticle.description?.description || 'No description available',
                    longDescription: productArticle.description?.longDescription || 'No details available'
                  },
                  hasPhoto: productArticle.hasPhoto || false,
                  images: productArticle.images || { F: [] },
                  prices: item.price,
                  quantity: item.quantity
                };

                return (
                  <li key={article.pharmacode} className="flex py-6 sm:py-10">
                    <div className="flex-shrink-0">
                      {(() => {
                        if (!article.hasPhoto || !article.images?.F?.length) {
                          return (
                            <div className="h-24 w-24 sm:h-48 sm:w-48 flex items-center justify-center bg-gray-100 rounded-lg border border-gray-200">
                              <span className="text-gray-400">No Image</span>
                            </div>
                          );
                        }

                        const picFront3DImage = article.images.F.find(image => image.includes('PICFRONT3D'));
                        const imageToDisplay = picFront3DImage || article.images.F[0];
                        const imageUrl = `https://pharma-outlet.com/uploads/${imageToDisplay.replace(/\\/g, '/')}`;

                        return (
                          <OptimizedImage
                            src={imageUrl}
                            alt={article.description?.longDescription || 'Product Image'}
                            className="h-24 w-24 rounded-lg object-contain object-center sm:h-48 sm:w-48 border border-gray-200"
                            isPriority={false}
                          />
                        );
                      })()}
                    </div>

                    <div className="ml-4 flex flex-1 flex-col justify-between sm:ml-6">
                      <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
                        <div>
                          <h3 className="text-base font-medium text-gray-900">
                            {article.description?.longDescription}
                          </h3>
                          <p className="mt-1 text-sm text-gray-500 line-clamp-2">
                            {article.description?.description}
                          </p>
                          <p className="mt-2 text-sm font-medium text-gray-900">
                            CHF {Number(article.prices).toLocaleString('en-CH', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </p>
                        </div>

                        <div className="mt-4 sm:mt-0 sm:pr-9">
                          <label htmlFor={`quantity-${article.pharmacode}`} className="sr-only">
                            Quantity
                          </label>
                          <div className="mt-4 sm:mt-0 sm:pr-9">
  <label htmlFor={`quantity-${article.pharmacode}`} className="sr-only">
    Quantity
  </label>
  <div className="flex items-center">
    <div className="flex items-center mr-4">
      <button
        type="button"
        onClick={() => {
          const newValue = Math.max(1, article.quantity - 1);
          handleQuantityChange(article.pharmacode, newValue);
        }}
        className="rounded-l-md border border-gray-300 px-3 py-1 text-gray-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-[#3A7861]"
      >
        -
      </button>
      <input
        type="number"
        id={`quantity-${article.pharmacode}`}
        value={article.quantity}
        min="1"
        onChange={(e) => {
          const newValue = parseInt(e.target.value, 10);
          if (!isNaN(newValue) && newValue >= 1) {
            handleQuantityChange(article.pharmacode, newValue);
          }
        }}
        className="w-14 border-y border-gray-300 py-1 text-center focus:outline-none focus:ring-2 focus:ring-[#3A7861] [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
      />
      <button
        type="button"
        onClick={() => handleQuantityChange(article.pharmacode, article.quantity + 1)}
        className="rounded-r-md border border-gray-300 px-3 py-1 text-gray-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-[#3A7861]"
      >
        +
      </button>
    </div>
    <button
      type="button"
      onClick={() => handleRemoveFromCart(item.productId)}
      className="text-sm font-medium text-[#3A7861] hover:text-[#2B5B48]"
    >
      Remove
    </button>
  </div>
</div>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </section>

          {/* Order summary */}
        {/* Order summary */}
<section
  aria-labelledby="summary-heading"
  className="mt-16 rounded-lg bg-white px-4 py-6 sm:p-6 lg:col-span-5 lg:mt-0 lg:p-8 shadow-lg"
>
  <h2 id="summary-heading" className="text-lg font-medium text-gray-900">
    Order summary
  </h2>

  {/* Product Details Summary */}
  <div className="mt-6 space-y-4 max-h-64 overflow-y-auto">
    {cartWithDetails.map((item) => {
      const productArticle = item.product?.product?.articles?.find(
        article => article.pharmacode === item.pharmacode
      ) || {};
      
      const longDescription = productArticle.description?.longDescription || 'No details available';
      const quantity = item.quantity;
      const price = parseFloat(item.price) || 0;
      const itemTotal = price * quantity;

      return (
        <div key={item.pharmacode} className="flex justify-between py-2 border-b border-gray-200">
          <div className="flex-1">
            <p className="text-sm text-gray-600 line-clamp-2">{longDescription}</p>
            <p className="text-sm text-gray-500 mt-1">
              {quantity} x CHF {price.toLocaleString('en-CH', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
          </div>
          <div className="ml-4 text-sm font-medium text-gray-900">
            CHF {itemTotal.toLocaleString('en-CH', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        </div>
      );
    })}
  </div>

  {/* Totals */}
  <dl className="mt-6 space-y-4">
    <div className="flex items-center justify-between border-gray-200">
      <dt className="text-base font-medium text-gray-900">Order total</dt>
      <dd className="text-base font-medium text-gray-900">
        CHF {calculateSubtotal().toLocaleString('en-CH', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </dd>
    </div>
  </dl>

  <div className="mt-6">
    <button
      type="button"
      onClick={() => navigate('/checkout')}
      className="w-full rounded-md border border-transparent bg-[#568774] px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-[#376c7a] focus:outline-none focus:ring-2 focus:ring-[#dd774c] focus:ring-offset-2 focus:ring-offset-gray-50 transition-colors duration-200"
    >
      Proceed to Checkout
    </button>
  </div>
</section>
        </div>
      </main>
    </div>
  );
}