const vaccinationDetails = [
  {
    vaccination: "Zeckenimpfung",
    details: `Preis: CHF 47.35 (Impfstoff) zuzüglich CHF 20.00 (Impfservice)
Alter: Ab 16 Jahren
Die Zeckenimpfung wird insgesamt dreimal verabreicht. Es wird jedoch unterschieden zwischen dem normalen- und dem Schnellimpfschema.`,
  },
  {
    vaccination: "Grippeimpfung",
    details: `Preis: CHF 13.70 (Impfstoff) zuzüglich CHF 20.00 (Impfservice)
Alter: Ab 16 Jahren
Die Impfung ist saisonal abhängig (Start meist im Oktober / November bis Februar)`,
  },
  {
    vaccination: "Herpes Zoster",
    details: `Preis: CHF 174.65 (Impfstoff) zuzüglich CHF 20.00 (Impfservice)
Alter: Ab 18 Jahren, empfohlen wird die Impfung ab dem 65. Lebensjahr`,
  },
  {
    vaccination: "Diphtherie, Tetanus und Pertussis",
    details: `Preis: CHF 36.30 (Impfstoff) zuzüglich CHF 20.00 (Impfservice)
Alter: Ab 16 Jahren`,
  },
  {
    vaccination: "Hepatitis A + B",
    details: `Hepatitis A Preis: CHF 53.00 (Impfstoff) zuzüglich CHF 20.00 (Impfservice)
Die Hepatitis A Impfung wird in zwei Dosen verabreicht.`,
  },
  {
    vaccination: "Impfung gegen Mumpf, Masern und Röteln",
    details: `Preis: CHF 36.60 zuzüglich CHF 20.00 (Impfservice) Die Impfung gegen Mumpf, Masern und Röteln wird in zwei Dosen verabreicht.`,
  },
];

export default function VaccFAQs() {
  return (
    <div className="bg-white py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mb-12 text-center">
          <h2 className="text-4xl font-extrabold text-gray-800">Impfungen</h2>
          <p className="mt-4 text-lg text-gray-600 max-w-2xl mx-auto">
            Unser engagiertes Team in der Apotheke steht Ihnen zur Verfügung, um sicherzustellen, dass Sie die notwendigen Impfungen schnell und bequem erhalten.
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {vaccinationDetails.map((faq, index) => (
            <div
              key={index}
              className="bg-white rounded-xl shadow-md p-6 border border-gray-100 hover:shadow-lg transition-shadow"
            >
              <div className="flex items-center gap-3 mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  className="h-6 w-6 text-[#00A1BB]"
                  viewBox="0 0 24 24"
                >
                  <path d="M4 2C3.447 2 3 2.447 3 3v18c0 .553.447 1 1 1h16c.553 0 1-.447 1-1V7l-6-6H4zM17 15h-2v2c0 .553-.447 1-1 1s-1-.447-1-1v-2h-2c-.553 0-1-.447-1-1s.447-1 1-1h2v-2c0-.553.447-1 1-1s1 .447 1 1v2h2c.553 0 1 .447 1 1s-.447 1-1 1z" />
                </svg>
                <h3 className="text-2xl font-semibold text-gray-900">{faq.vaccination}</h3>
              </div>
              <p className="text-gray-700 whitespace-pre-line">{faq.details}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
