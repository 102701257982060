export default function OurServices() {
  return (
    <div className="bg-[#00A1BB] py-12 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="text-center mx-auto max-w-2xl">
          <h2 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl">UNSERE DIENSTLEISTUNG</h2>
          <p className="mt-4 text-lg leading-8 text-white">
            Ab Oktober 2023 führen wir bei uns in der Dreirosen Apotheke COVID-Booster Impfungen durch. Termine können im untenstehenden Kalender vereinbart werden. Damit die Impfung auch sicher verabreicht werden kann, bitten wir Sie mit uns vorab Kontakt aufzunehmen.
          </p>
        </div>
        <div className="mx-auto mt-12 grid gap-8 max-w-2xl lg:max-w-none lg:grid-cols-2">
          <div className="p-6 rounded-xl bg-white/10 backdrop-blur-lg shadow-lg ring-1 ring-inset ring-white/30 hover:bg-white/20 transition-transform transform hover:-translate-y-1">
            <div className="flex items-center gap-3">
              <h3 className="text-2xl font-bold text-white">WER WIRD GEIMPFT?</h3>
            </div>
            <p className="mt-3 text-base leading-7 text-white">
              Bei uns in der Dreirosen Apotheke werden folgende Personen gemäss den aktuellen Empfehlungen des BAG gratis geimpft:
            </p>
            <ul className="mt-4 list-disc pl-5 text-white">
              <li>Personen welche über 65 Jahre alt sind</li>
              <li>Personen mit Vorerkrankungen (z.B. Herzerkrankung, Diabetes etc.)</li>
            </ul>
            <p className="mt-4 text-white text-base">
              Sollten Sie nicht zu dieser Zielgruppe gehören, so können Sie sich bei uns kostenpflichtig impfen lassen. Die Kosten für die COVID-Impfung betragen CHF 117.60 (Impfstoff + Dienstleistung).
            </p>
            <p className="mt-4 text-white text-base">
              Da wir die Impfung nicht direkt über die Krankenversicherung abrechnen können, sind wir verpflichtet den Betrag von allen vor Ort einzukassieren. Bei den Risikopatienten wird der Betrag von der Krankenversicherung rückerstattet!
            </p>
          </div>
          <div className="p-6 rounded-xl bg-white/10 backdrop-blur-lg shadow-lg ring-1 ring-inset ring-white/30 hover:bg-white/20 transition-transform transform hover:-translate-y-1">
            <div className="flex items-center gap-3">
              <h3 className="text-2xl font-bold text-white">WAS MÜSSEN SIE MITBRINGEN?</h3>
            </div>
            <p className="mt-3 text-base leading-8 text-white">
              Bitte bringen Sie am Impftermin folgende Unterlagen mit:
            </p>
            <ul className="mt-4 list-disc pl-5 text-white space-y-3">
              <li>
                Schweizerische Krankenversicherungskarte (falls vorhanden)
              </li>
              <li>
                Identitätskarte oder Pass (inkl. Aufenthaltsbewilligung falls vorhanden)
              </li>
              <li>
                Impfbüchlein
              </li>
              <li>
                Nachweis der bisher erhaltenen COVID-19 Impfungen (wenn möglich das Impfzertifikat elektronisch oder ausgedruckt mit lesbarem QR-Code)
              </li>
              <li>
                Personen mit einer Immunschwäche: Überweisung des behandelnden Arztes oder Ärztin
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
