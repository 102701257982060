import React from 'react';

export default function Services() {
  const services = [
    'Beratung und Auskunft in allen Gesundheits- und Medikamentenfragen',
    'Kassenverrechnung von ärztlichen Rezepten',
    'Beratung und gegebenenfalls Abgabe der Pille-danach',
    'Beratung und Anpassung von medizinischen Kompressionsstrümpfen und Bandagen',
    'Zusammenstellung und Kontrolle von Haus-, Reise- und Sportapotheken',
    'Wir führen für Sie den Polymedikationscheck durch',
    'Besorgung von nicht in unserem Sortiment geführten Produkten (innert ½ bis 1 Tag)',
    'Wir entsorgen Ihre Altmedikamente und Gifte gemäss den gesetzlichen Richtlinien.',
    'Wir können Nasen- und Ohrlöcher stechen und haben auch den passenden Schmuck dazu.',
    'Wir sind Ihr lokaler Gesundheitspartner im Quartier',
    'Wir messen kostenlos Ihren Blutdruck.',
    'Wir messen kostenlos Ihr Körpergewicht und berechnen Ihren Body-Mass-Index.',
    'Wir haben Broschüren und Artikellisten betreffend Alterspflege, Rheuma-Alltagshilfen oder Fortbewegungsmittel wie Rollatoren oder Krücken.',
  ];

  const equipment = [
    'Elektrische Milchpumpen',
    'Inhalationsgeräte',
    'Elsa-Testkissen',
  ];

  const CheckCircleSVG = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6 text-[#4D7F6E]"
      fill="currentColor"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm-1 15L7 13l1.4-1.4 2.6 2.6 5.6-5.6L18 10z" />
    </svg>
  );

  return (
    <div className="bg-gray-50 px-6 py-16 lg:px-8">
      <div className="max-w-5xl mx-auto">
        <header className="mb-12 text-center">
          <p className="text-lg font-semibold leading-7 text-[#3B725C]">Dreirosen Apotheke</p>
          <h1 className="mt-3 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">Unsere Dienstleistungen</h1>
          <p className="mt-4 text-lg text-gray-600">
            Die Dreirosen Apotheke bietet Ihnen eine Vielzahl an Dienstleistungen, um Ihre Gesundheit und Ihr Wohlbefinden zu unterstützen.
          </p>
        </header>

        <div className="space-y-12">
          {/* Services Section */}
          <section className="bg-white border border-gray-200 rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow duration-300">
            <h2 className="text-2xl font-semibold mb-4">Unsere Angebote</h2>
            <ul className="space-y-4">
              {services.map((service, index) => (
                <li key={index} className="flex items-start gap-x-3">
                  <CheckCircleSVG />
                  <span className="text-gray-700">{service}</span>
                </li>
              ))}
            </ul>
          </section>

          {/* Equipment Section */}
          <section className="bg-white border border-gray-200 rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow duration-300">
            <h2 className="text-2xl font-semibold mb-4">Gerätschaften mieten</h2>
            <ul className="space-y-4">
              {equipment.map((item, index) => (
                <li key={index} className="flex items-start gap-x-3">
                  <CheckCircleSVG />
                  <span className="text-gray-700">{item}</span>
                </li>
              ))}
            </ul>
          </section>

          {/* Cooperation Section */}
          <section className="bg-white border border-gray-200 rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow duration-300">
            <h2 className="text-2xl font-semibold mb-4">Kooperationen</h2>
            <p className="text-gray-700">
              Die Dreirosen Apotheke ist ein zuverlässiger Partner für Alters- und Pflegeheime, Arztpraxen und weitere Institutionen, bei welchem die Gesundheit der Individuen im Vordergrund steht.
            </p>
            <p className="mt-4 text-gray-700">
              Gerne offerieren wir Ihnen individuelle Dienstleistungen, welche Ihren Bedürfnissen entsprechend ausgearbeitet werden.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}
