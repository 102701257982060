import React from 'react';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import Register from '../components/Authentication/Register';

function RegisterPage() {
  return (
    <div>
      <Register />
    </div>
  );
}

export default RegisterPage;



