import { useState, useEffect, Suspense, useMemo } from 'react';
import { Disclosure, Dialog } from '@headlessui/react';
import { 
  fetchAllProducts, 
  searchProducts, 
  toggleFavoriteProduct, 
  fetchFavorites, 
  fetchCategories,
  fetchGenericProducts 
} from '../../services/apiService';
import { useNavigate, useLocation } from 'react-router-dom';

// Image dimensions configuration
const IMAGE_DIMENSIONS = {
  thumbnail: { width: 300, height: 300 },
  medium: { width: 600, height: 600 },
  large: { width: 900, height: 900 }
};

// Optimized image component with loading states
const OptimizedImage = ({ src, alt, className, isPriority = false }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  return (
    <div className="relative w-full h-full">
      {isLoading && (
        <div className="absolute inset-0 bg-gray-200 animate-pulse" />
      )}
      <picture>
        <img
          src={src}
          alt={alt}
          width={IMAGE_DIMENSIONS.medium.width}
          height={IMAGE_DIMENSIONS.medium.height}
          className={`${className} ${isLoading ? 'opacity-0' : 'opacity-100'} transition-opacity duration-300`}
          loading={isPriority ? "eager" : "lazy"}
          decoding="async"
          onLoad={() => setIsLoading(false)}
          onError={() => {
            setIsLoading(false);
            setError(true);
          }}
        />
      </picture>
      {error && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
          <span className="text-gray-500">Image not available</span>
        </div>
      )}
    </div>
  );
};

// Product Card Component
const ProductCard = ({ article, favorite, onFavoriteClick, onProductClick, isPriority }) => {
  return (
    <div
      onClick={() => onProductClick(article.pharmacode)}
      className="group relative border border-gray-200 rounded-lg bg-white shadow-sm p-4 flex flex-row lg:flex-col hover:shadow-lg transition-shadow duration-200 cursor-pointer"
    >
      <button
        onClick={(e) => {
          e.stopPropagation();
          onFavoriteClick(article.pharmacode);
        }}
        className="absolute top-2 right-2 z-10"
      >
        {favorite[article.pharmacode] ? (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-[#e05257]" viewBox="0 0 24 24" fill="currentColor">
            <path fillRule="evenodd" d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/>
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-[#e05257]" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M20.84 4.61a5.5 5.5 0 00-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 00-7.78 7.78L12 21.23l8.84-8.84a5.5 5.5 0 000-7.78z" />
          </svg>
        )}
      </button>

      <div className="flex-shrink-0 w-32 h-32 mr-4 lg:w-full lg:h-64 lg:mb-4">
        {(() => {
          if (!article.hasPhoto || !article.images || !Array.isArray(article.images.M) || article.images.M.length === 0) {
            return (
              <div className="h-full w-full flex items-center justify-center text-gray-500">
                <span>No Image Available</span>
              </div>
            );
          }

          const picFront3DImage = article.images.M.find(image => image.includes('PICFRONT3D'));
          const imageToDisplay = picFront3DImage || article.images.M[0];
          const imageUrl = `https://pharma-outlet.com/uploads/${imageToDisplay.replace(/\\/g, '/')}`;

          return (
            <OptimizedImage
              src={imageUrl}
              alt={article.description?.longDescription || 'Product Image'}
              className="h-full w-full object-contain"
              isPriority={isPriority}
            />
          );
        })()}
      </div>

      <div className="flex-grow flex flex-col justify-between text-left lg:text-center">
        <div>
          <h3 className="text-sm font-medium text-gray-900">{article.description?.description || 'Bschrieb nid verfüegbar'}</h3>
          <p className="italic text-gray-500 line-clamp-2">{article.description?.longDescription || 'Detail nid verfüegbar'}</p>
          {
            article.prices ? (
              <p className="mt-2 font-medium text-gray-900">
                {article.prices} CHF
              </p>
            ) : (
              <p className="mt-2 font-medium text-gray-900">Pris nid verfüegbar</p>
            )
          }
        </div>

        {/* Regulation-based shipping information */}
        {article.regulations?.length > 0 && (
          <div className="mt-2 text-sm flex items-center justify-start lg:justify-center">
            {article.regulations[0].code.id === 'Sale.Web.Rx' && (
              <div className="flex items-center">
                <p className="text-[#E05257]">Shipping not possible</p>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1 text-[#E05257]" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path d="M3 3.75A.75.75 0 013.75 3h16.5a.75.75 0 01.75.75v2.5a.75.75 0 01-.75.75h-16.5a.75.75 0 01-.75-.75v-2.5zM4.5 10.5a.75.75 0 01.75-.75h13.5a.75.75 0 01.75.75v7.25a.75.75 0 01-.75.75H5.25a.75.75 0 01-.75-.75V10.5z" />
                </svg>
              </div>
            )}
            {article.regulations[0].code.id === 'Sale.Web.CC' && (
              <div className="flex items-center">
                <p className="text-[#E05257]">Shipping not possible</p>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1 text-[#E05257]" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path d="M3 3.75A.75.75 0 013.75 3h16.5a.75.75 0 01.75.75v2.5a.75.75 0 01-.75.75h-16.5a.75.75 0 01-.75-.75v-2.5zM4.5 10.5a.75.75 0 01.75-.75h13.5a.75.75 0 01.75.75v7.25a.75.75 0 01-.75.75H5.25a.75.75 0 01-.75-.75V10.5z" />
                </svg>
              </div>
            )}
            {article.regulations[0].code.id === 'Sale.Web.OK' && (
              <div className="flex items-center">
                <p className="text-[#4D7F6E]">Shipping possible</p>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1 text-[#4D7F6E]" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path d="M3 3.75A.75.75 0 013.75 3h16.5a.75.75 0 01.75.75v2.5a.75.75 0 01-.75.75h-16.5a.75.75 0 01-.75-.75v-2.5zM4.5 10.5a.75.75 0 01.75-.75h13.5a.75.75 0 01.75.75v7.25a.75.75 0 01-.75.75H5.25a.75.75 0 01-.75-.75V10.5z" />
                </svg>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const staticCategories = [
  "Medikamente & Behandlung",
  "Ernährung & Gesundheit",
  "Baby & Eltern",
  "Beauty & Körperpflege",
  "Haushalt & Reinigung",
  "Sanitätsartikel & Krankenpflege"
];


const filters = [
];


export default function AllProducts({ currentPage, setCurrentPage, setTotalPages }) {
  const [products, setProducts] = useState([]);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [favorite, setFavorite] = useState({});
  const [fetchedCategories, setFetchedCategories] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [lastSearchTerm, setLastSearchTerm] = useState('');
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get('search');
  const genericCode = queryParams.get('genericCode');
  const level = queryParams.get('level');
  const view = queryParams.get('view');


  useEffect(() => {
    const fetchAndDisplayProducts = async () => {
      setLoading(true);
      const query = new URLSearchParams(location.search);
      const searchTerm = query.get('search');
      const genericCode = query.get('genericCode');
      const level = query.get('level');
      const view = query.get('view');
      
      if (searchTerm !== lastSearchTerm) {
        setCurrentPage(1);
        setLastSearchTerm(searchTerm);
      }
      
      try {
        let response;
        
        if (view === 'generic' && genericCode && level) {
          // Handle generic products view
          response = await fetchGenericProducts(genericCode, level, currentPage, 20);
          if (response?.success) {
            setProducts(response.data.products || []);
            setTotalPages(response.data.totalPages || 1);
          }
        } else if (searchTerm) {
          // Handle search results
          response = await searchProducts(searchTerm, currentPage);
          if (response?.success && response.data) {
            setProducts(response.data.products || []);
            setTotalPages(response.data.totalPages || 1);
          }
        } else {
          // Handle all products
          response = await fetchAllProducts(currentPage, 20);
          if (response?.success && response.data) {
            setProducts(response.data.products || []);
            setTotalPages(response.data.totalPages || 1);
          }
        }

        if (!response?.success) {
          console.error('Invalid response structure:', response);
          setProducts([]);
          setTotalPages(1);
        }
      } catch (error) {
        console.error('Failed to fetch products:', error);
        setProducts([]);
        setTotalPages(1);
      } finally {
        setLoading(false);
      }
    };

    fetchAndDisplayProducts();
  }, [location.search, currentPage, lastSearchTerm]);


  useEffect(() => {
    const fetchAndMapCategories = async () => {
      try {
        const response = await fetchCategories();
        const fetchedData = response.data;


        const categoryMapping = {
          "Medikamente & Behandlung": ["PainFever", "FluColds"],
          "Ernährung & Gesundheit": ["Nutrition"],
          "Baby & Eltern": ["MotherChild"],
          "Beauty & Körperpflege": ["FacialSkinCareMakeUp", "SkinHairNails"],
          "Haushalt & Reinigung": ["HouseGarden"],
          "Sanitätsartikel & Krankenpflege": ["FirstAidDressingMat"]
        };


        const mappedCategories = {};
        staticCategories.forEach((staticCategory) => {
          const relevantCategories = categoryMapping[staticCategory];
          const matchingCategories = fetchedData.filter((category) =>
            relevantCategories.includes(category.category.en)
          );
          mappedCategories[staticCategory] = matchingCategories;
        });


        setFetchedCategories(mappedCategories);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    fetchAndMapCategories();
  }, []);


  const handleViewProduct = (pharmacode) => {
    navigate(`/product/${pharmacode}`);
  };


  const handleFavoriteClick = async (pharmacode) => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/loginpage');
      return;
    }


    try {
      await toggleFavoriteProduct(pharmacode);
      setFavorite((prev) => ({
        ...prev,
        [pharmacode]: !prev[pharmacode],
      }));
    } catch (error) {
      console.error('Error toggling favorite:', error);
    }
  };


  return (
    <div className="bg-white">
      <div>
        <Dialog open={mobileFiltersOpen} onClose={setMobileFiltersOpen} className="relative z-40 lg:hidden">
          <div className="fixed inset-0 bg-black bg-opacity-25 transition-opacity" />
          <div className="fixed inset-0 z-40 flex">
            <div className="relative ml-auto flex h-full w-full max-w-xs transform flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
              <div className="flex items-center justify-between px-4">
                <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                <button
                  type="button"
                  onClick={() => setMobileFiltersOpen(false)}
                  className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                >
                  <span className="sr-only">Close menu</span>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>


              <form className="mt-4 border-t border-gray-200">
                {filters.map((section) => (
                  <Disclosure key={section.id} as="div" className="border-t border-gray-200 px-4 py-6">
                    <h3 className="-mx-2 -my-3 flow-root">
                    <Disclosure.Button className="group flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                      <span className="font-medium text-gray-900">{section.name}</span>
                      <span className="ml-6 flex items-center">
                        {/* Plus Icon */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 group-open:hidden"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                          aria-hidden="true"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
                        </svg>
                        {/* Minus Icon */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 hidden group-open:block"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                          aria-hidden="true"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" d="M20 12H4" />
                        </svg>
                      </span>
                    </Disclosure.Button>
                    </h3>
                    <Disclosure.Panel className="pt-6">
                      <div className="space-y-6">
                        {section.options.map((option, optionIdx) => (
                          <div key={option.value} className="flex items-center">
                            <input
                              defaultValue={option.value}
                              defaultChecked={option.checked}
                              id={`filter-mobile-${section.id}-${optionIdx}`}
                              name={`${section.id}[]`}
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <label htmlFor={`filter-mobile-${section.id}-${optionIdx}`} className="ml-3 min-w-0 flex-1 text-gray-500">
                              {option.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </Disclosure.Panel>
                  </Disclosure>
                ))}
              </form>
            </div>
          </div>
        </Dialog>


        <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-10">
              <h1 className="text-3xl font-bold tracking-tight text-gray-900">
                {view === 'generic' 
                  ? 'Generische Produkte'
                  : location.search 
                    ? 'Search Results' 
                    : 'All Products'}
              </h1>
            <div className="flex items-center">
              <button type="button" onClick={() => setMobileFiltersOpen(true)} className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 hidden">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2a1 1 0 01-.293.707l-5.414 5.414a1 1 0 00-.293.707V20l-4-4v-5.172a1 1 0 00-.293-.707L3.293 6.707A1 1 0 013 6V4z" />
                </svg>
                <span className="sr-only">Filters</span>
              </button>
            </div>
          </div>


          <section aria-labelledby="products-heading" className="pb-24 pt-6 grid grid-cols-1 lg:grid-cols-4 gap-x-8">
            <form className="hidden lg:block lg:col-span-1">
              <h3 className="sr-only">Categories</h3>
              <ul className="space-y-4 border-b border-gray-200 pb-6 text-md font-medium text-[#4D7F6E]">
                <li key="all">
                  <a href="/products">All Products</a>
                </li>
              </ul>


              {staticCategories.map((categoryName) => (
                <Disclosure key={categoryName} as="div" className="border-b border-gray-200 py-6">
                  <h3 className="-my-3 flow-root">
                    <Disclosure.Button className="group flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                      <span className="font-medium text-gray-900">{categoryName}</span>
                      <span className="ml-6 flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 group-data-[open]:hidden" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" aria-hidden="true">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 [.group:not([data-open])_&]:hidden" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" aria-hidden="true">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M20 12H4" />
                        </svg>
                      </span>
                    </Disclosure.Button>
                  </h3>
                  <Disclosure.Panel className="pt-6">
                    <div className="space-y-4">
                      {fetchedCategories[categoryName]?.map((category, idx) => (
                        <ul key={idx} className="px-1 space-y-1">
                          {category.subcategories.map((subcategory, subIdx) => (
                            <li key={subIdx}>
                              <button
                                onClick={() => {
                                  const cleanSubcategoryId = subcategory.en.trim();
                                  navigate(`/categories/${cleanSubcategoryId}/products`, { replace: true });
                                }}
                                className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                              >
                                {subcategory.de}
                              </button>
                            </li>
                          ))}
                        </ul>
                      ))}
                    </div>
                  </Disclosure.Panel>
                </Disclosure>
              ))}


              {filters.map((section) => (
                <Disclosure key={section.id} as="div" className="border-b border-gray-200 py-6">
                  <h3 className="-my-3 flow-root">
                  <Disclosure.Button className="group flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                    <span className="font-medium text-gray-900">{section.name}</span>
                    <span className="ml-6 flex items-center">
                      {/* Plus Icon */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 group-open:hidden"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                        aria-hidden="true"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
                      </svg>
                      {/* Minus Icon */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 hidden group-open:block"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                        aria-hidden="true"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M20 12H4" />
                      </svg>
                    </span>
                  </Disclosure.Button>
                  </h3>
                  <Disclosure.Panel className="pt-6">
                    <div className="space-y-4">
                      {section.options.map((option, optionIdx) => (
                        <div key={option.value} className="flex items-center">
                          <input
                            defaultValue={option.value}
                            defaultChecked={option.checked}
                            id={`filter-${section.id}-${optionIdx}`}
                            name={`${section.id}[]`}
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                          <label htmlFor={`filter-${section.id}-${optionIdx}`} className="ml-3 text-sm text-gray-600">
                            {option.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  </Disclosure.Panel>
                </Disclosure>
              ))}
            </form>

            <div className="lg:col-span-3 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3">
  {loading ? (
    <div className="flex justify-center items-center h-64 col-span-full">
  <svg
                  className="animate-spin h-10 w-10 text-[#3A7861]"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8H4z"
                  ></path>
                </svg>
      <span className="ml-2 text-gray-500">Loading products...</span>
    </div>
  ) : products && products.length > 0 ? (
    products.flatMap((product) => {
      if (!product || !product._id) return [];

      // Handle products with nested articles (generic products)
      if (product.articles && Array.isArray(product.articles)) {
        return product.articles.map(article => {
          const processedArticle = {
            pharmacode: article.pharmacode,
            description: {
              description: article.description?.description || 'No description available',
              longDescription: article.description?.longDescription || 'No description available'
            },
            hasPhoto: article.hasPhoto || false,
            images: {
              M: Array.isArray(article.images?.M) ? article.images.M.map(img => img.replace(/^uploads\\/, '')) : []
            },
            prices: article.prices || null
          };

          return (
            <ProductCard
              key={article.pharmacode}
              article={processedArticle}
              favorite={favorite}
              onFavoriteClick={handleFavoriteClick}
              onProductClick={handleViewProduct}
              isPriority={false}
            />
          );
        });
      }

      // Handle regular products
      const article = {
        pharmacode: product._id,
        description: {
          description: product.description?.description || 'No description available',
          longDescription: product.description?.longDescription || product.longDescription || 'No description available'
        },
        hasPhoto: product.hasPhoto || false,
        images: {
          M: Array.isArray(product.images) ? product.images.map(img => img.replace(/^uploads\\/, '')) : []
        },
        prices: product.prices?.price || null
      };

      return (
        <ProductCard
          key={product._id}
          article={article}
          favorite={favorite}
          onFavoriteClick={handleFavoriteClick}
          onProductClick={handleViewProduct}
          isPriority={false}
        />
      );
    })
  ) : (
    <div className="text-center col-span-full">
      <h3 className="text-lg font-medium text-gray-900">No products found.</h3>
      <p className="text-gray-500">Try a different search term.</p>
    </div>
  )}
</div>
          </section>
        </main>
      </div>
    </div>
  );
}
