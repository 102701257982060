import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { searchProducts } from '../services/apiService';

const SearchBarCompact = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [previewResults, setPreviewResults] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const searchRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowPreview(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (searchTerm.trim().length >= 2) {
      setIsLoading(true);
      setShowPreview(true);

      const fetchResults = async () => {
        try {
          const response = await searchProducts(searchTerm, 1, 5);
          if (response?.success && Array.isArray(response.data.products)) {
            setPreviewResults(response.data.products);
          }
        } catch (error) {
          console.error('Search preview failed:', error);
          setPreviewResults([]);
        } finally {
          setIsLoading(false);
        }
      };

      const timer = setTimeout(fetchResults, 150);
      return () => clearTimeout(timer);
    } else {
      setPreviewResults([]);
      setShowPreview(false);
    }
  }, [searchTerm]);

  const handlePreviewClick = (pharmacode) => {
    navigate(`/product/${pharmacode}`);
    setShowPreview(false);
    setSearchTerm('');
    setIsOpen(false);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      navigate(`/products?search=${encodeURIComponent(searchTerm)}`);
    }
  };

  const getOptimizedImageUrl = (images) => {
    if (!Array.isArray(images) || images.length === 0) return null;
    
    // Try to find PICFRONT3D first
    const picFront3D = images.find(image => image.includes('PICFRONT3D'));
    if (picFront3D) {
      return picFront3D.replace(/\\/g, '/');
    }
    
    // Then try to find any PICFRONT image
    const picFront = images.find(image => image.includes('PICFRONT'));
    if (picFront) {
      return picFront.replace(/\\/g, '/');
    }
    
    // Fallback to the first image
    return images[0].replace(/\\/g, '/');
  };

  const MagnifyingGlassSVG = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-9 w-8"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
      aria-hidden="true"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21 21l-4.35-4.35M16.1 10.95a5.15 5.15 0 11-10.3 0 5.15 5.15 0 0110.3 0z"
      />
    </svg>
  );

  const XMarkSVG = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
      aria-hidden="true"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  );

  return (
    <>
      <button
        className="text-gray-500 hover:text-gray-900"
        onClick={() => setIsOpen(true)}
      >
        <MagnifyingGlassSVG />
      </button>

      {isOpen && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-start justify-center pt-0">
          <div ref={searchRef} className="relative w-full max-w-lg p-8 bg-white shadow-lg transform transition-all ease-in-out duration-300 scale-100">
            <button
              className="absolute top-2 right-1 text-gray-400 hover:text-gray-600 focus:outline-none"
              onClick={() => setIsOpen(false)}
            >
              <XMarkSVG />
            </button>
            <form onSubmit={handleSearchSubmit} className="relative flex items-center">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full border border-gray-300 rounded-full py-3 pl-4 pr-12 text-gray-700 focus:outline-none focus:ring-2 focus:ring-green-500 shadow-sm transition-all duration-200"
                placeholder="Search for products..."
              />
              <button
                type="submit"
                className="absolute right-3 p-2 text-gray-500 hover:text-gray-900 focus:outline-none bg-transparent"
              >
                <MagnifyingGlassSVG />
              </button>
            </form>

            {showPreview && searchTerm.trim().length >= 2 && (
              <div className="w-full mt-2 bg-white rounded-lg shadow-xl border border-gray-200 max-h-96 overflow-y-auto">
                {isLoading ? (
                  <div className="p-4 text-center text-gray-500">Loading...</div>
                ) : previewResults.length > 0 ? (
                  <div className="py-2">
                    {previewResults.map((product) => {
                      const article = {
                        pharmacode: product._id,
                        description: {
                          description: product.description?.description || 'No description available',
                          longDescription: product.description?.longDescription || product.longDescription || 'No description available'
                        },
                        hasPhoto: product.hasPhoto || false,
                        images: {
                          M: Array.isArray(product.images) ? product.images.map(img => img.replace(/^uploads\\/, '')) : []
                        },
                        prices: product.prices?.price || null
                      };

                      return (
                        <div
                          key={article.pharmacode}
                          onClick={() => handlePreviewClick(article.pharmacode)}
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center space-x-3"
                        >
                          <div className="w-12 h-12 flex-shrink-0">
                            {(() => {
                              if (!article.hasPhoto || !article.images.M || !Array.isArray(article.images.M) || article.images.M.length === 0) {
                                return (
                                  <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                                    <span className="text-xs text-gray-500">No image</span>
                                  </div>
                                );
                              }

                              const imageUrl = getOptimizedImageUrl(article.images.M);
                              
                              return (
                                <img
                                  src={`https://pharma-outlet.com/uploads/${imageUrl}`}
                                  alt={article.description.description}
                                  className="w-full h-full object-contain"
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.parentElement.innerHTML = '<div class="w-full h-full bg-gray-200 flex items-center justify-center"><span class="text-xs text-gray-500">No image</span></div>';
                                  }}
                                />
                              );
                            })()}
                          </div>
                          <div className="flex-1 min-w-0">
                            <p className="text-sm font-medium text-gray-900 truncate">
                              {article.description.longDescription}
                            </p>
                            <p className="text-sm text-gray-500">
                              {article.prices ? `${article.prices} CHF` : 'Price not available'}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="p-4 text-center text-gray-500">No results found</div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SearchBarCompact;