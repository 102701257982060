
import Nav from "../components/Nav"; 
import Footer from '../components/Footer';
import MenuServices from '../components/MenuServices';


function MenuServicesPage() {
    return (
      <div>
       <Nav />
       <MenuServices />
       <Footer />
      </div>
    );
  }
  
  export default MenuServicesPage;
  