// src/components/Shop/CategorySidebar.js
import { Disclosure } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';

const staticCategories = [
  "Medikamente & Behandlung",
  "Ernährung & Gesundheit",
  "Baby & Eltern",
  "Beauty & Körperpflege",
  "Haushalt & Reinigung",
  "Sanitätsartikel & Krankenpflege"
];

export default function CategorySidebar({ fetchedCategories }) {
  const navigate = useNavigate();

  const handleCategoryClick = (subcategoryEn) => {
    const cleanSubcategoryId = subcategoryEn.trim();
    navigate(`/categories/${cleanSubcategoryId}/products`, { replace: true });
  };

  return (
    <form className="hidden lg:block lg:col-span-1">
      <h3 className="sr-only">Categories</h3>
      <ul className="space-y-4 border-b border-gray-200 pb-6 text-md font-medium text-[#4D7F6E]">
        <li key="all">
          <a href="/products">All Products</a>
        </li>
      </ul>

      {staticCategories.map((categoryName) => (
        <Disclosure key={categoryName} as="div" className="border-b border-gray-200 py-6">
          <h3 className="-my-3 flow-root">
            <Disclosure.Button className="group flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
              <span className="font-medium text-gray-900">{categoryName}</span>
              <span className="ml-6 flex items-center">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  className="h-5 w-5 group-data-[open]:hidden" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor" 
                  strokeWidth="2" 
                  aria-hidden="true"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    d="M12 4v16m8-8H4" 
                  />
                </svg>
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  className="h-5 w-5 [.group:not([data-open])_&]:hidden" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor" 
                  strokeWidth="2" 
                  aria-hidden="true"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    d="M20 12H4" 
                  />
                </svg>
              </span>
            </Disclosure.Button>
          </h3>
          <Disclosure.Panel className="pt-6">
            <div className="space-y-4">
              {fetchedCategories[categoryName]?.map((category, idx) => (
                <ul key={idx} className="px-1 space-y-1">
                  {category.subcategories.map((subcategory, subIdx) => (
                    <li key={subIdx}>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          handleCategoryClick(subcategory.en);
                        }}
                        className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      >
                        {subcategory.de}
                      </button>
                    </li>
                  ))}
                </ul>
              ))}
            </div>
          </Disclosure.Panel>
        </Disclosure>
      ))}
    </form>
  );
}