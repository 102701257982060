import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop'; // Import ScrollToTop component
import Home from './pages/Home';
import Products from './pages/Products';
import ProductDetailsPage from './pages/ProductDetailsPage';
import CartPage from './pages/CartPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import OrderDetailsPage from './pages/OrderDetailsPage';
import VacinationsPage from './pages/VacinationsPage';
import VacAppointmentPage from './pages/VacAppointmentPage';
import ProfilePage from './pages/ProfilePage';
import ProtectedRoute from './components/ProtectedRoute';
import BlogPage from './pages/BlogPage';
import UserFavoritesPage from './pages/UserFavoritesPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import VerifyEmailPage from './pages/VerifyEmailPage';
import PaymentPage from './pages/PaymentPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import FAQPage from './pages/FAQPage';
import AboutUsPage from './pages/AboutUsPage';
import CategoryList from './components/CategoryList';
import CategoryResultsPage from './pages/CategoryResultsPage';
import JobsPage from './pages/JobsPage';
import MenuServicesPage from './pages/MenuServicesPage';
import ArticlePage1 from './pages/ArticlePage1';
import ArticlePage2 from './pages/ArticlePage2';
import ArticlePage3 from './pages/ArticlePage3';

function App() {
  return (
    <BrowserRouter>
      {/* Add ScrollToTop here */}
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products" element={<Products />} />
        <Route path="/product/:pharmacode" element={<ProductDetailsPage />} />
        <Route path="/cartpage" element={<CartPage />} />
        <Route path="/loginpage" element={<LoginPage />} />
        <Route path="/registerpage" element={<RegisterPage />} />
        <Route path="/orderdetailspage" element={<OrderDetailsPage />} />
        <Route path="/vacinationspage" element={<VacinationsPage />} />
        <Route path="/vacappointment" element={<VacAppointmentPage />} />
        <Route path="/blogs" element={<BlogPage />} />
        <Route path="/userfavoritespage" element={<UserFavoritesPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path="/verify-email" element={<VerifyEmailPage />} />
        <Route path="/payment" element={<PaymentPage />} />
        <Route path="/privacypolicy" element={<PrivacyPolicyPage />} />
        <Route path="/faqs" element={<FAQPage />} />
        <Route path="/aboutus" element={<AboutUsPage />} />
        <Route path="/categories" element={<CategoryList />} />
        <Route path="/categories/:subcategoryId/products" element={<CategoryResultsPage />} />
        <Route path="/jobs" element={<JobsPage />} />
        <Route path="/services" element={<MenuServicesPage />} />
        <Route path="/fasten-konvertiert" element={<ArticlePage1 />} />
        <Route path="/enigma-schlaf" element={<ArticlePage2 />} />
        <Route path="/Ferien-konvertiert" element={<ArticlePage3 />} />
        <Route
          path="/profilepage"
          element={
            <ProtectedRoute>
              <ProfilePage />
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
