import React, { useEffect, useRef, useMemo, useState } from "react";

const Bestsellers = () => {
  const products = useMemo(
    () => [
      {
        id: 1,
        title: "Propolis Mundspray Fl 20 ml",
        price: "15.95 CHF",
        image: "images/resized_BS1.webp",
        link: "http://pharma-outlet.com/product/5543484",
      },
      {
        id: 2,
        title: "EMS Sinusitis Spray mit Eukalyptusöl 15 ml",
        price: "8.33 CHF",
        image: "images/resized_BS2.webp",
        link: "http://pharma-outlet.com/product/7815203",
      },
      {
        id: 3,
        title: "Avene Sun Sonnencreme getönt SPF50+ Tb 50 ml",
        price: "20.95 CHF",
        image: "images/resized_BS3.webp",
        link: "http://pharma-outlet.com/product/7815203",
      },
      {
        id: 4,
        title: "Clinique All About Eyes 15 ml",
        price: "29.55 CHF",
        image: "images/resized_BS5.webp",
        link: "http://pharma-outlet.com/product/3491725",
      },
      {
        id: 5,
        title: "Avene Sun Mineralische Sonnencreme SPF 50+ 50 ml",
        price: "20.95 CHF",
        image: "images/resized_BS6.webp",
        link: "http://pharma-outlet.com/product/2778554",
      },
      {
        id: 6,
        title: "Voltaren Dolo Emulgel Tb 60 g",
        price: "7.95 CHF",
        image: "images/resized_BS4.webp",
        link: "http://pharma-outlet.com/product/2836719",
      },
    ],
    []
  );

  const scrollContainerRef = useRef(null);
  const [activeCard, setActiveCard] = useState(null);

  useEffect(() => {
    const container = scrollContainerRef.current;
    const cards = container.querySelectorAll(".bestseller-card");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveCard(Number(entry.target.dataset.id));
          }
        });
      },
      {
        root: container,
        threshold: 0.7, // Trigger when 70% of the card is visible
      }
    );

    cards.forEach((card) => observer.observe(card));

    return () => {
      cards.forEach((card) => observer.unobserve(card));
    };
  }, []);

  return (
    <div className="w-full sm:mt-0 lg:mt-20 px-4 py-0 md:px-12">
      <h2 className="text-4xl font-bold text-center text-black">Bestseller</h2>
      <p className="mx-auto mt-3 max-w-2xl text-xl text-gray-600 mb-10 sm:mt-4">
        Entdecken Sie die Produkte, die unsere Kunden am meisten lieben.
      </p>
      <div
        className="relative overflow-x-auto hide-scrollbar snap-x snap-mandatory"
        ref={scrollContainerRef}
      >
        <div className="flex space-x-4 pb-4 px-4">
          {products.map((product) => (
            <div
              key={product.id}
              data-id={product.id}
              className={`bestseller-card flex-none w-[90%] sm:w-[80%] md:w-72 bg-white shadow-md rounded-lg p-4 transition-transform duration-300 relative text-center snap-center ${
                activeCard === product.id && window.innerWidth < 768
                  ? "scale-105"
                  : "scale-100"
              }`}
            >
              <a href={product.link} rel="noopener noreferrer">
                <img
                  src={product.image}
                  alt={product.title}
                  className="w-full h-auto max-h-56 mb-4 object-contain rounded-md"
                  loading="lazy"
                  width="300"
                  height="300"
                />
              </a>
              <h3 className="text-lg font-semibold mb-1">{product.title}</h3>
              <p className="text-gray-800 font-medium mb-2">{product.price}</p>
            </div>
          ))}
        </div>
      </div>
      <style>{`
        .hide-scrollbar {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
        .hide-scrollbar::-webkit-scrollbar {
          display: none;
        }
        @media (min-width: 768px) {
          .bestseller-card {
            transform: none !important;
          }
        }
      `}</style>
    </div>
  );
};

export default Bestsellers;
