import Nav from "../components/Nav"; 
import Footer from '../components/Footer';
import Banner from '../components/Banner';
import Artcile2 from "../components/HealthArticles/Article2";


function ArticlePage2() {
    return (
      <div>
      <Banner />
       <Nav />
       <Artcile2 />
       <Footer />
      </div>
    );
  }
  
  export default ArticlePage2;
  