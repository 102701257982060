import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchProductsBySubcategory, fetchCategories } from '../services/apiService';
import { useNavigate } from 'react-router-dom';
import Pagination from './Shop/Pagination';
import CategorySidebar from './Shop/CategorySidebar';

export default function CategoryResults() {
  const { subcategoryId } = useParams();
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [fetchedCategories, setFetchedCategories] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const getProducts = async () => {
      try {
        setLoading(true);
        // Remove any trailing characters from subcategoryId
        const cleanSubcategoryId = subcategoryId.trim();
        const response = await fetchProductsBySubcategory(cleanSubcategoryId, currentPage);
        setProducts(response.data.products);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setLoading(false);
      }
    };
    getProducts();
  }, [subcategoryId, currentPage]);

  useEffect(() => {
    const fetchAndMapCategories = async () => {
      try {
        const response = await fetchCategories();
        const fetchedData = response.data;

        const categoryMapping = {
          "Medikamente & Behandlung": ["PainFever", "FluColds"],
          "Ernährung & Gesundheit": ["Nutrition"],
          "Baby & Eltern": ["MotherChild"],
          "Beauty & Körperpflege": ["FacialSkinCareMakeUp", "SkinHairNails"],
          "Haushalt & Reinigung": ["HouseGarden"],
          "Sanitätsartikel & Krankenpflege": ["FirstAidDressingMat"]
        };

        const mappedCategories = {};
        Object.keys(categoryMapping).forEach((staticCategory) => {
          const relevantCategories = categoryMapping[staticCategory];
          const matchingCategories = fetchedData.filter((category) =>
            relevantCategories.includes(category.category.en)
          );
          mappedCategories[staticCategory] = matchingCategories;
        });

        setFetchedCategories(mappedCategories);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    fetchAndMapCategories();
  }, []);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleViewProduct = (pharmacode) => {
    console.log('Navigating to product with pharmacode:', pharmacode);
    navigate(`/product/${pharmacode}`);
  };

  return (
    <div className="bg-white">
      <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-12">
          <h1 className="sm:text-3xl text-2xl font-bold tracking-tight text-gray-900">{subcategoryId}</h1>
        </div>
        
        <section aria-labelledby="products-heading" className="pb-24 pt-6 grid grid-cols-1 lg:grid-cols-4 gap-x-8">
          <CategorySidebar fetchedCategories={fetchedCategories} />

          <div className="lg:col-span-3 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3">
            {loading ? (
              <div className="flex justify-center items-center h-64 col-span-full">
                <svg
                  className="animate-spin h-10 w-10 text-[#3A7861]"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8H4z"
                  ></path>
                </svg>
                <span className="ml-2 text-gray-500">Loading products...</span>
              </div>
            ) : products.length > 0 ? (
              products.map((product) =>
                product.articles.map((article) => (
                  <div
                    key={article.pharmacode}
                    onClick={() => handleViewProduct(article.pharmacode)}
                    className="group relative border border-gray-200 rounded-lg bg-white shadow-sm p-4 flex flex-row lg:flex-col hover:shadow-lg transition-shadow duration-200 cursor-pointer"
                  >
                    {/* Image section */}
                    <div className="flex-shrink-0 w-32 h-32 mr-4 lg:w-full lg:h-64 lg:mb-4">
                      {(() => {
                        if (!article.hasPhoto || !article.images || !Array.isArray(article.images.M) || article.images.M.length === 0) {
                          return (
                            <div className="h-full w-full flex items-center justify-center text-gray-500">
                              <span>No Image Available</span>
                            </div>
                          );
                        }

                        const picFront3DImage = article.images.M.find((image) =>
                          image.includes('PICFRONT3D')
                        );
                        const imageToDisplay = picFront3DImage || article.images.M[0];

                        return (
                          <img
                            src={`https://pharma-outlet.com/uploads/${imageToDisplay.replace(/\\/g, '/')}`}
                            alt={article.description?.longDescription || 'Product Image'}
                            className="h-full w-full object-contain lg:object-contain"
                          />
                        );
                      })()}
                    </div>

                    {/* Product details section */}
                    <div className="flex-grow flex flex-col justify-between text-left lg:text-center">
                      <div>
                        <h3 className="text-sm font-medium text-gray-900">{article.description?.description || 'Bschrieb nid verfüegbar'}</h3>
                        <p className="italic text-gray-500 line-clamp-2">{article.description?.longDescription || 'Detail nid verfüegbar'}</p>
                        {article.prices ? (
                          <p className="mt-2 font-medium text-gray-900">
                            {article.prices} CHF
                          </p>
                        ) : (
                          <p className="mt-2 font-medium text-gray-900">Pris nid verfüegbar</p>
                        )}
                      </div>

                      {/* Regulation-based shipping information */}
                      {article.regulations?.length > 0 && (
                        <div className="mt-2 text-sm flex items-center justify-start lg:justify-center">
                          {article.regulations[0].code.id === 'Sale.Web.Rx' && (
                            <div className="flex items-center">
                              <p className="text-[#E05257]">Shipping not possible</p>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4 mr-1 text-[#E05257]"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                                aria-hidden="true"
                              >
                                <path d="M4 3h16v14H4V3zm2 2v10h12V5H6zm-3 15h18v2H3v-2z" />
                              </svg>
                            </div>
                          )}
                          {article.regulations[0].code.id === 'Sale.Web.CC' && (
                            <div className="flex items-center">
                              <p className="text-[#E05257]">Shipping not possible</p>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4 mr-1 text-[#E05257]"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                                aria-hidden="true"
                              >
                                <path d="M4 3h16v14H4V3zm2 2v10h12V5H6zm-3 15h18v2H3v-2z" />
                              </svg>
                            </div>
                          )}
                          {article.regulations[0].code.id === 'Sale.Web.OK' && (
                            <div className="flex items-center">
                              <p className="text-[#4D7F6E]">Shipping possible</p>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4 mr-1 text-[#4D7F6E]"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                                aria-hidden="true"
                              >
                                <path d="M4 3h16v14H4V3zm2 2v10h12V5H6zm-3 15h18v2H3v-2z" />
                              </svg>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                ))
              )
            ) : (
              <div className="text-center col-span-full">
                <h3 className="text-lg font-medium text-gray-900">No products found.</h3>
                <p className="text-gray-500">Try a different search term.</p>
              </div>
            )}
          </div>
        </section>
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
      </main>
    </div>
  );
}
