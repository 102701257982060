import ProgressBar from "../components/Labor/ProgressBar";
import AppointmentForm from "../components/Labor/AppointmentForm";


function VacAppointmentPage() {
    return (
      <div>
       <ProgressBar />
       <AppointmentForm />
      </div>
    );
  }
  
  export default VacAppointmentPage;
